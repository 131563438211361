import ZEEE00000NATIS from "../../assets/img/ZEEE00000NATIS.webp";
import default_fallback from "../../assets/img/default_fallback.webp";
import BYJU00000NATWK from "../../assets/img/BYJU00000NATWK.webp";
import BAJA00000NATFG from "../../assets/img/BAJA00000NATFG.webp";
import FITP00000NATQ9 from "../../assets/img/FITP00000NATQ9.webp";
import FURL00000NATF6 from "../../assets/img/FURL00000NATF6.webp";
import HTDI00000NATC5 from "../../assets/img/HTDI00000NATC5.webp";
import HIND00000NATRT from "../../assets/img/HIND00000NATRT.webp";
import HUNG00000NATJL from "../../assets/img/HUNG00000NATJL.webp";
import HUNG00000NATDP from "../../assets/img/HUNG00000NATDP.webp";
import ENTE00000NAT8T from "../../assets/img/ENTE00000NAT8T.webp";
import PARE00000NAT6L from "../../assets/img/PARE00000NAT6L.webp";
import SHEM00000NATFQ from "../../assets/img/SHEM00000NATFQ.webp";
import SHEM00000NATUG from "../../assets/img/SHEM00000NATUG.webp";
import APOL00000NATLV from "../../assets/img/APOL00000NATLV.webp";
import SONY00000NAT0P from "../../assets/img/SONY00000NAT0P.webp";
import MYUP00000NAT9U from "../../assets/img/MYUP00000NAT9U.webp";

export const subscriptionsData = {
  operatorTileData: [
    { id: "1", icon: ZEEE00000NATIS, title: "ZEE5" },
    {
      id: "2",
      icon: default_fallback,
      title: "Ashok Book Centre",
    },
    { id: "3", icon: BYJU00000NATWK, title: "BYJUS" },
    {
      id: "4",
      icon: BAJA00000NATFG,
      title: "Bajaj Finserv Health Limited",
    },
    { id: "5", icon: FITP00000NATQ9, title: "FITPASS" },
    { id: "6", icon: FURL00000NATF6, title: "Furlenco" },
    { id: "7", icon: HTDI00000NATC5, title: "HT Digital" },
    {
      id: "8",
      icon: HIND00000NATRT,
      title: "Hindustan Times Newspaper",
    },
    { id: "9", icon: HUNG00000NATJL, title: "Hungama Music" },
    { id: "10", icon: HUNG00000NATDP, title: "Hungama Play" },
    {
      id: "11",
      icon: default_fallback,
      title: "Jyoshith Jewellers",
    },
    {
      id: "12",
      icon: default_fallback,
      title: "Manashakti Rest New Way",
    },
    { id: "13", icon: default_fallback, title: "MediBuddy" },
    { id: "14", icon: MYUP00000NAT9U, title: "MyUpchar" },
    {
      id: "15",
      icon: ENTE00000NAT8T,
      title: "Nupay - Entellus Business Solutions Pvt Ltd",
    },
    { id: "16", icon: PARE00000NAT6L, title: "Parentlane" },
    {
      id: "17",
      icon: SHEM00000NATFQ,
      title: "Shemaroo -IBADAT",
    },
    {
      id: "18",
      icon: SHEM00000NATUG,
      title: "Shemaroo Entertainment -Bhakti",
    },
    {
      id: "19",
      icon: default_fallback,
      title: "Sree Bharat Labs",
    },
    {
      id: "20",
      icon: default_fallback,
      title: "The Indian Express",
    },
    { id: "21", icon: APOL00000NATLV, title: "APOLLO 24/7" },
    { id: "22", icon: SONY00000NAT0P, title: "SonyLIV" },
  ],
};
