import React from "react"
import { ElectricityData } from "../../pages/electricity/MockData"
import { OperatorTile } from "../common/operatorTile/OperatorTile"

function AboutAllProvider() {
    const electricityData = ElectricityData
    
    return (

        <div className="search-layout-section providers-list">
            <div className="section-head">All Providers</div>
            {electricityData.allProviders ? electricityData.allProviders.data.map((item) => {
                return <div className="search-tile-wrapper " key={item.id}>
                    <div className="group-head ">{item.state}</div>
                    <div className="grouped-operator-tiles">
                        {item.distributers.map((item) => {
                            return <OperatorTile data={item} key={item.id} />

                        })}
                    </div>
                </div>
            }) : <></>}
            <div className="about-section">
                <div className="content-section" style={{ maxHeight: 100 + "%" }}>
                    {electricityData.aboutData.map((item) => {
                        return <React.Fragment key={item.id}>
                            <h1>{item.heading}</h1>
                            {item.paragraphs.map((item, i) => {
                                return <p key={i}>{item.para}</p>
                            })}
                        </React.Fragment>
                    })}
                </div>
            </div>
        </div>
    )
}
export default AboutAllProvider