import mtIcon from "../../assets/img/mt_icon.svg";
import mtSvg from "../../assets/img/mt.svg";
import searchIcon from "../../assets/img/search-icon.png";
import AllProvider from "./allProvider/AllProvider";
import { mockData } from "../../pages/taxPayment/MockData";
import { useState } from "react";
import ContaintPayment from "./containtPayment/ContaintPayment";
const url = "bg-rent.svg";

function TaxPaymentForm() {
  const [data, setData] = useState(mockData);
  const [store, setStore] = useState({ value: "" });

  const onChangeHandle = (event) => {
    const newStore = { ...store }; 
    newStore.value = event.target.value;
    setStore(newStore);
  };
  // console.log(store);

  return (
    <div className="main-container" style={{ background: `url(${url})` }}>
      <div className="main-wrapper">
        <div className="main-content color-banner w-80">
          <div className="search-layout new-layout">
            <div className="desktop-operators-main-header review-bill-header">
              <div className="desktop-heading-image">
                <img
                  className="heading-image"
                  src={mtIcon}
                  title="Municipal Tax Payment Done!"
                  alt="Municipal Tax Payment Done!"
                />
              </div>
              <div className="desktop-header-text">
                <div className="main-heading">Let's Get Your</div>
                <div className="sub-heading">Municipal Tax Payment Done!</div>
              </div>
              <img
                src={mtSvg}
                title="Municipal Tax Payment Done!"
                alt="Municipal Tax Payment Done!"
              />
            </div>
            <div className="search-layout-section search-input-wrapper  sticky">
              <div className="desktop-search-heading">Search</div>
              <div className="search-layout-field-wrapper ">
                <div className="search-layout-field-icon">
                  <img src={searchIcon} alt="search field icon" />
                </div>
                <div className="input__InputContainerWrapper-sc-77palv-0 gxoua input-wrapper search-layout-input-wrapper ">
                  <div className="input__InputContainer-sc-77palv-1 kjqNrV input-container">
                    <input
                      type="text"
                      name="value"
                      placeholder="Search"
                      width="100%"
                      className="input__InputWrapper-sc-77palv-4 ldAVAW"
                      onChange={onChangeHandle}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="search-layout-collapsible-section  collapsed">
              <div className="search-layout-section"></div>
              <div className="location-info-box">
                <div>
                  <p className="heading">Grant Location Access</p>
                  <span className="desc">
                    Allow Location access to show up your nearby service
                    providers
                  </span>
                </div>
                <p className="cta">ALLOW</p>
              </div>
            </div>
            <div className="search-layout-section providers-list">
              <div className="section-head">All Providers</div>
              {data.allprovider.map((item) => {
                return (
                  
                    <AllProvider key={item.id} allprovider={item} />
                  
                );
              })}
            </div>
            <div className="about-section">
              <div className="content-section">
                {data.containtList.map((item) => {
                  return <ContaintPayment key={item.id} aboutSection={item} />;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default TaxPaymentForm;
