import fastagMockData from "../../pages/fastag/MockData";
import { OperatorTile } from "../common/operatorTile/OperatorTile";
import ContaintFastag from "./containtFastag/ContaintFastag";
import img from "../../assets/img/ft_icon.svg";
import img2 from "../../assets/img/ft.svg";
import img3 from "../../assets/img/search-icon.png";

const FasTagComp = () => {
  return (
    <>
      <main id="fastag_page">
        <div className="main-container" style={{ background: "transparent" }}>
          <div className="main-wrapper">
            <div className="main-content color-banner w-80">
              <div className="search-layout new-layout">
                {/* search header start */}
                <div className="desktop-operators-main-header review-bill-header">
                  <div className="desktop-heading-image">
                    <img
                      className="heading-image"
                      src={img}
                      title="FASTag Recharge Done!"
                      alt="FASTag Recharge Done!"
                    />
                  </div>
                  <div className="desktop-header-text">
                    <div className="main-heading">Let's Get Your</div>
                    <div className="sub-heading">FASTag Recharge Done!</div>
                  </div>
                  <img
                    src={img2}
                    title="FASTag Recharge Done!"
                    alt="FASTag Recharge Done!"
                  />
                </div>
                {/* search header end */}

                <div className="search-layout-section search-input-wrapper  sticky">
                  <div className="desktop-search-heading">Search</div>
                  <div className="search-layout-field-wrapper ">
                    <div className="search-layout-field-icon">
                      <img src={img3} alt="search field icon" />
                    </div>
                    <div className="input__InputContainerWrapper-sc-77palv-0 gxoua input-wrapper search-layout-input-wrapper ">
                      <div className="input__InputContainer-sc-77palv-1 kjqNrV input-container">
                        <input
                          type="text"
                          name="search-field"
                          placeholder="Search"
                          width="100%"
                          autoComplete="off"
                          className="input__InputWrapper-sc-77palv-4 ldAVAW"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="search-layout-collapsible-section  collapsed"
                  style={{ maxHeight: 100 + "%" }}
                >
                  <div className="search-layout-section"></div>
                </div>
                <div className="search-layout-section providers-list">
                  <div className="section-head">All Providers</div>
                  <div className="all-providers-tile">
                    {fastagMockData.allProvider.map((item) => {
                      return <OperatorTile data={item} key={item.id} />;
                    })}
                  </div>
                </div>
              </div>
              <div className="about-section">
                <div className="content-section">
                  {fastagMockData.content.map((item) => {
                    return <ContaintFastag key={item.id} aboutSection={item} />;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default FasTagComp;
