import React from 'react'
import { mockData } from '../../pages/privacyPolicy/MockData'

function PrivacyPolicy() {
    return <section>
        {/* main--container  */}
        <div className="main-wrapper border border-1 rounded-3 bg-white my-5">
            <div className="home-tab-wrapper ">
                <div className="grid-section">
                    <h2 className=" text-center border-bottom mb-5 py-4">Privacy Policy</h2>

                    <div className='w-75 m-auto p-5'>

                        <ol>
                            {mockData.data.map((item) => {
                                console.log(item);
                                return <li className='list-style'>
                                    <p className='mb-4'>{item?.para}</p>
                                    <p className='mb-2'>{item?.paraTit}</p>
                                    <p className='text-black mb-1'>{item?.one}</p>
                                    <p className='text-black mb-1'>{item?.two}</p>
                                    <p className='text-black mb-1'>{item?.three}</p>
                                    <p className='text-black mb-1'>{item?.four}</p>
                                    <p className='text-black mb-1'>{item?.five}</p>
                                    <p className='text-black mb-1'>{item?.six}</p>
                                </li>
                            })}

                        </ol>
                    </div>

                </div>
                {/* <!-- ====home-slider-section --> */}

            </div>
        </div>
    </section>
}

export default PrivacyPolicy
