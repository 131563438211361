import React from 'react'
import { mockData } from '../../pages/refundPolicy.js/MockData'

function RefundPolicies() {
    return <section>
        {/* main--container  */}
        <div className="main-wrapper border border-1 rounded-3 bg-white my-5">
            <div className="home-tab-wrapper ">
                <div className="grid-section">
                    <h2 className=" text-center border-bottom mb-5 py-4">Refund Policy</h2>

                    <div className='w-75 m-auto p-5'>
                        <ol>
                            {mockData.map((item) => {
                                return <li>
                                    <h4 className='list-style mb-3'>{item?.question}</h4>
                                    <p className='mb-5'>{item.para}</p>
                                    <p className='mb-5'>{item.para2}</p>
                                </li>
                            })}

                        </ol>
                    </div>

                </div>
                {/* <!-- ====home-slider-section --> */}

            </div>
        </div>
    </section>
}

export default RefundPolicies
