import React from 'react';
import searchIcon from "../../assets/img/search-icon.png";
import cableTv from "../../assets/img/cable-tv-recharge_2.webp";
import tvRecharge from "../../assets/img/cable-tv-recharge.svg";


import { cableTvRechargeData } from "../../pages/cableTvRecharge/mockData";

function cableTvRecharge() {
    const handlerData = () => {
        console.log("Hello");
    }
    return (
        <>
            <div className="main-content color-banner w-80">
                <div className="search-layout new-layout">
                    <div className="desktop-operators-main-header review-bill-header">
                        <div className="desktop-heading-image">
                            <img className="heading-image" src={cableTv} title="Cable TV Recharge Done!" alt="Cable TV Recharge Done!" />
                        </div>
                        <div className="desktop-header-text">
                            <div className="main-heading">Let's Get Your</div>
                            <div className="sub-heading">Cable TV Recharge Done!</div>
                        </div>
                        <img src={tvRecharge} title="Cable TV Recharge Done!" alt="Cable TV Recharge Done!" />
                    </div>
                    <div className="search-layout-section search-input-wrapper  sticky">
                        <div className="desktop-search-heading">Search</div>
                        <div className="search-layout-field-wrapper ">
                            <div className="search-layout-field-icon">
                                <img src={searchIcon} alt="search field icon" /></div>
                            <div className="input__InputContainerWrapper-sc-77palv-0 gxoua input-wrapper search-layout-input-wrapper ">
                                <div className="input__InputContainer-sc-77palv-1 kjqNrV input-container">
                                    <input type="text"
                                        name="search-field"
                                        placeholder="Search"
                                        width="100%"
                                        autoComplete="off"
                                        className="input__InputWrapper-sc-77palv-4 ldAVAW"
                                        onChange={handlerData} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="search-layout-section providers-list">
                        <div className="section-head">All Providers</div>
                        <div className="all-providers-tile">
                            {cableTvRechargeData.map((item) => {
                                return <div key={item.id} className="operator-tile  flex-center" tabIndex="0">
                                    <div className="logo-container">
                                        <img src={item.icon} alt="Operator Logo" className="operator-img" />
                                    </div>
                                    <div className="operator-content">
                                        <div className="operator-name">{item.title}</div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                    <div className="about-section">
                        <div className="content-section" style={{ maxHeight: "100%" }}>
                            <h1><span style={{ color: "#d86c06" }}>Make Cable TV Recharge Online With Abaris Payment</span>
                            </h1>
                            <p>Both the Abaris Payment App (available for users of Android and iOS) and the website
                                allow you to pay your cable TV bill online without any issues. <a href="https://www.Abaris Payment.in/cable-tv-recharge/gtpl-hathway-limited">GTPL
                                    online recharge</a>,
                                <a href="#">INDigital online
                                    recharge</a>,
                                <a href="#">Hathway
                                    cable recharge</a>,
                                <a href="#">Intermedia
                                    cable recharge</a>,
                                <a href="#">Asianet cable
                                    recharge</a>
                                etc are among the cable operators for which you may pay your
                                monthly cable TV bills online.
                                </p>
                            <h2>How to Pay Your Cable Bill Online at Abaris Payment?</h2>
                            <div>Quickly paying your cable TV bill is incredibly easy and hassle-free with
                                Abaris Payment. To finish it, you only need to follow a few simple instructions and make
                                the necessary payments. To learn more, follow the steps listed below.</div>

                            <ul>
                                <li>Please enter your Account No./ AV No./ MAC No./ RMN, Customer No./ SetupBox No.</li>
                                <li>Click Next</li>
                                <li>Choose your favourite Cable TV bill payment coupons to receive Cashback and other benefits. (if applicable)</li>
                                <li>Debit/Credit Card, Net Banking, or Abaris Payment Wallet are the available payment  options. You need not worry about your money because our payment options are  secure and safe.</li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default cableTvRecharge