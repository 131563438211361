

import AGL000000MAP01 from '../../assets/img/AGL000000MAP01.webp'
import ADAN00000NAT01 from '../../assets/img/ADAN00000NAT01.webp'
import ASSA00000ASMA5 from '../../assets/img/ASSA00000ASMA5.webp'
import BHAG00000NATBJ from '../../assets/img/BHAG00000NATBJ.webp'
import CUGL00000UTP01 from '../../assets/img/CUGL00000UTP01.webp'
import CGSM00000GUJ01 from '../../assets/img/CGSM00000GUJ01.webp'
import GAIL00000IND01 from '../../assets/img/GAIL00000IND01.webp'
import GAIL00000NATTZ from '../../assets/img/GAIL00000NATTZ.webp'
import GGL000000UTP01 from '../../assets/img/GGL000000UTP01.webp'
import GUJA00000GUJYT from '../../assets/img/GUJA00000GUJYT.webp'
import HCG000000HAR02 from '../../assets/img/HCG000000HAR02.webp'
import IEPL00000GUJ01 from '../../assets/img/IEPL00000GUJ01.webp'
import INDI00000NAT26 from '../../assets/img/INDI00000NAT26.webp'
import IOAG00000DEL01 from '../../assets/img/IOAG00000DEL01.webp'
import INDRAPGASDEL02 from '../../assets/img/INDRAPGASDEL02.webp'
import MAHA00000MUM01 from '../../assets/img/MAHA00000MUM01.webp'
import MNGL00000MAH02 from '../../assets/img/MNGL00000MAH02.webp'
import MEGH00000NATLV from '../../assets/img/MEGH00000NATLV.webp'
import NAVE00000MAPEN from '../../assets/img/NAVE00000MAPEN.webp'
import PURB00000NATIU from '../../assets/img/PURB00000NATIU.webp'
import RAJA00000NAT5O from '../../assets/img/RAJA00000NAT5O.webp'
import SGL000000GUJ01 from '../../assets/img/SGL000000GUJ01.webp'
import TORR00000AHD0P from '../../assets/img/TORR00000AHD0P.webp'
import TNGCLOB00TRI01 from '../../assets/img/TNGCLOB00TRI01.webp'
import UCPGPL000MAH01 from '../../assets/img/UCPGPL000MAH01.webp'
import VGL000000GUJ01 from '../../assets/img/VGL000000GUJ01.webp'
import GUJGAS000GUJ01 from '../../assets/img/GUJGAS000GUJ01.webp'


export const  gasPayementData = [
    {id:"1",icon:AGL000000MAP01,title:"Aavantika Gas Ltd"},
    {id:"2",icon:ADAN00000NAT01,title:"Adani Gas"},
    {id:"3",icon:ASSA00000ASMA5,title:"Assam Gas Company Limited"},
    {id:"4",icon:BHAG00000NATBJ,title:"Bhagyanagar Gas Limited"},
    {id:"5",icon:CUGL00000UTP01,title:"Central U.P. Gas Limited"},
    {id:"6",icon:CGSM00000GUJ01,title:"Charotar Gas Sahakari Mandali Ltd"},
    {id:"7",icon:GAIL00000IND01,title:"GAIL Gas Limited"},
    {id:"8",icon:GAIL00000NATTZ,title:"GAIL India Limited"},
    {id:"9",icon:GGL000000UTP01,title:"Green Gas Limited (GGL)"},
    {id:"10",icon:GUJGAS000GUJ01,title:"Gujarat Gas Limited"},
    {id:"11",icon:GUJA00000GUJYT,title:"Gujarat State Petronet Limited (GSPL)"},
    {id:"12",icon:HCG000000HAR02,title:"Haryana City Gas - Kapil Chopra Enterprise"},
    {id:"13",icon:IEPL00000GUJ01,title:"IRM Energy Private Limited"},
    {id:"14",icon:INDI00000NAT26,title:"Indian Oil Corporation Ltd (IOCL) - Piped Gas"},
    {id:"15",icon:IOAG00000DEL01,title:"Indian Oil-Adani Gas Private Limited"},
    {id:"16",icon:INDRAPGASDEL02,title:"Indraprastha Gas (IGL)"},
    {id:"17",icon:MAHA00000MUM01,title:"Mahanagar Gas (MGL)"},
    {id:"18",icon:MNGL00000MAH02,title:"Maharashtra Natural Gas Limited (MNGL)"},
    {id:"19",icon:MEGH00000NATLV,title:"Megha Gas"},
    {id:"20",icon:NAVE00000MAPEN,title:"Naveriya Gas Pvt Ltd"},
    {id:"21",icon:PURB00000NATIU,title:"Naveriya Gas Pvt Ltd"},
    {id:"22",icon:RAJA00000NAT5O,title:"Naveriya Gas Pvt Ltd"},
    {id:"23",icon:SGL000000GUJ01,title:"Sabarmati Gas"},
    {id:"24",icon:TORR00000AHD0P,title:"Sabarmati Gas"},
    {id:"25",icon:TNGCLOB00TRI01,title:"Tripura Natural Gas"},
    {id:"26",icon:UCPGPL000MAH01,title:"Unique Central Piped Gases Pvt Ltd (UCPGPL)"},
    {id:"27",icon:VGL000000GUJ01,title:"Vadodara Gas Limited (VGL)"},

]