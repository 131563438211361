import AboutAllProvider from "../../components/electricity/AboutAllProvider"
import InputSection from "../../components/electricity/InputSection"

function Electricity() {
    return (
        <div className="main-container" style={{ background: "transparent" }}>
            <div className="main-wrapper">
                <div className="main-content color-banner w-80">
                    <div className="search-layout new-layout">
                        <InputSection />
                        <AboutAllProvider />
                    </div>
                   
                </div>
            </div>
        </div>
    )
}
export default Electricity