import icon1 from  "./../../assets/img/PORT00000ANI1K.webp";
import icon2 from "./../../assets/img/DELH00000DEL6Q.webp";
import icon3 from "./../../assets/img/DLJB00000DEL01.webp";
import icon4 from "./../../assets/img/NDMC00000DEL01.webp";
import icon5 from "./../../assets/img/SMC000000DNH01.webp";
import icon6 from "./../../assets/img/SMC000000DNH01.webp";
import icon7 from "./../../assets/img/SMC000000GUJ01.webp";
import icon8 from "./../../assets/img/SMC000000GUJ01.webp";
import icon9 from "./../../assets/img/VATV00000GUJ91.webp";
import icon10 from "./../../assets/img/SHIM00000SHM5C.webp";
import icon11 from "./../../assets/img/PUBL00000HARBB.webp";
import icon12 from "./../../assets/img/HUDAOB.webp";
import icon13 from "./../../assets/img/MUNI00000HARG6.webp";
import icon14 from "./../../assets/img/JAMM00000JAKWZ.webp";
import icon15 from "./../../assets/img/JAMM00000JAKWZ.webp";
import icon16 from "./../../assets/img/RMC000000JHA01.webp"
import icon17 from "./../../assets/img/KERA00000KERMO.webp"
import icon18 from "./../../assets/img/BWSSB0000KAR01.webp"
import icon19 from "./../../assets/img/CMCI00000KARW0.webp"
import icon20 from "./../../assets/img/MCC000000KAR01.webp"
import icon21 from "./../../assets/img/SHIV00000KARJV.webp"
import icon22 from "./../../assets/img/VIJA00000KARUT.webp"

const waterMockData =  {
    waterData : [

      {
        state: "Andaman And Nicobar Islands",
        waterServices: [
          {
            title: "Port Blair Municipal Council - Water",
            icon: icon1,
          },
        
        ],
      },
      {
        state: "Delhi",
        waterServices: [
          {
            title: "Delhi Development Authority (DDA) - Water",
           icon: icon2,
          },
          {
              title:"Delhi Jal Board (DJB)",
              icon: icon3,
          },
          {
            title: "New Delhi Municipal Council (NDMC) - Water",
             icon: icon4,
          },
    
       
      
        ],
      },
      {
        state : "Dadra And Nagar Haveli",
        waterServices : [
         {
          title: "Silvassa Municipal Council",
          icon : icon6,
        }
        ]
      },
      {
        state : "Goa",
        waterServices : [
         {
          title: "Goa Public Works Department (PWD)",
          icon : icon7,
        }
        ]
      },
      {
        state:"Gujarat",
        waterServices : [
          {
            title: "Surat Municipal Corporation - Water",
            icon:icon8,
          },
          {
            title: "Vatva Industrial Estate Infrastructure Development Ltd",
            icon:icon9,
          }
        ]
      },
      {
        state : "Himachal Pradesh",
        waterServices : [
          {
            title:"Shimla Jal Prabandhan",
            icon:icon10,
          }
        ]
      }, 
      {
        state:"Haryana",
        waterServices : [
          {
            title:"Haryana PHED",
            icon:icon11,
          },
          {
            title:"Haryana Urban Development Authority (HUDA)",
            icon:icon12,
          },
          {
            title:"Municipal Corporation of Gurugram (MCG) - Water",
            icon:icon13,
          }
        ]
      },
     {
      state:"Jammu And Kashmir",
      waterServices : [
        {
          title:"Jammu JKPHE",
          icon:icon14,
        },
        {
          title:"Kashmir JKPHE",
          icon:icon15,
        }
      ]
     },
    
     {
      state:"Jharkhand",
      waterServices : [
        {
          title:"Ranchi Municipal Corporation",
          icon:icon16,
        }
      ]
     },
     {
      state:"Kerala",
      waterServices: [
        {
          title: "Kerala Water Authority (KWA)",
          icon:icon17,
        }
      ]
    },
    {
      state:'Karnataka',
      waterServices : [
        {
          title:"Bangalore Water Supply and Sewerage Board (BWSSB)",
          icon:icon18,
        },
        {
          title:"Ilkal City Municipal Council",
          icon:icon19,
        },
        {
          title:"Mysuru City Corporation",
          icon:icon20,
        },
        {
          title:"Shivamoga City Corporation - Water Tax",
          icon:icon21,
        },
        {
          title:"Vijayapura Water Board",
          icon:icon22,
        }
      ]
    }
   
    ],

    footerData : [
      {
        heading:'Instant Water Bill Payment',
        para1:'Your water bill payment is just a click away with Abaris Payment. Trusted by over 27 million users, Abaris Payment is your one-stop shop for online recharge!',
        para2:'Online water bill payment is a useful method, especially when one is running short of time. You do not need to stress over paper bills and cheques.',
        para3:'Online water bill payment can give you the complete flexibility of paying anytime, anywhere; all you need is internet access.',
        para4:'In the event that you are running late and need assistance in paying the water bill, online portals like Abaris Payment give quick relief. Utilise the assistance that Abaris Payment offers, as we provide a flexible solution that enables you to securely transfer your money using credit card, debit card, or net banking.'

      },
      {
        heading:'Abaris Payment For Online Water Bill Payment',
        para1:'Above all, you can save a lot of time and avoid paying late charges. It is also more secure than paying through the mail. Moreover, you can deal with your funds more effectively by paying water bills via Abaris Payment using your debit or credit card. Besides, you can also avail various rewards for online recharge.'
      }
    ]

 
  
}



export default  waterMockData;
