import React from 'react'
import RefundPolicies from '../../components/RefundPolicy/RefundPolicy'

function RefundPolicy() {
    return (
        <>
            <RefundPolicies />
        </>
    )
}

export default RefundPolicy
