import { Link } from "react-router-dom";

function PaymentsType({ list }) {
  // console.log('----',paymentOption)
  return (
    <>
      <li
        className="section-category-tile"
        title="Rent Payment"
        style={{ order: list.id }}
        key={list.id}
      >
        <Link to={list.path}>
          <div className="tile-img-tag">{list.icon}</div>
          <div className="tile-type">{list.title}</div>
        </Link>
      </li>
    </>
  );
}
export default PaymentsType;
