import logo from "../../assets/img/lb-logo.png";
import loginIcon from "../../assets/img/login-icon.png";
import { Link } from "react-router-dom";

export const TopNavbar = (props) => {

  return (
    <header className="main-header ">
      <div className="main-wrapper header-content">
        <div className="header-logo">
          <Link to={"/"}>
            <img src={logo} alt="logo" className="logo-header" style={{ width: '250px' }} />
          </Link>

        </div>
        <div className="header-options">
          <div className="header-login">
            <img
              src={loginIcon}
              alt="profile avatar"
              className="profile-avatar"
            />

            <span
              className="login-btn d-lg-block d-none"
              data-toggle="modal"
              onClick={props.openModal}
            >
              login / register
            </span>

            <button
              className=" d-lg-none d-block button__SecondaryButtonWrapper-ah8457-1 jEfuYg"
              width="100%"
              type="button"
              data-toggle="modal"
              href="#exampleModalToggle"
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};
