import React, { useState } from "react";
import { rentPaymentData } from "../../pages/rentPayment/mockData";

import cardssprite from "../../assets/img/cardssprite.png";
import radio_inactive_neo_banking_2 from "../../assets/img/radio_inactive_neo_banking_2.svg";
import radio_active_neo_banking_2 from "../../assets/img/radio_active_neo_banking_2.svg";
import BSESRAJPLDEL01 from "../../assets/img/BSESRAJPLDEL01.webp";
import bbpslogo from "../../assets/img/bbpslogo.webp";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
export const PaymentOption = () => {
  const { paymentOption } = rentPaymentData;
  const { allBanks } = rentPaymentData;
  const [toggle, setToggle] = useState("1");
  const onClickHandle = (id) => {
    setToggle(id);
  };
  const [cardDetail, setCardDetail] = useState({
    cardNumber: "",
    validity: "",
    cvv: "",
  });
  const { cardNumber, validity, cvv } = cardDetail;
  const onChangeHandle = (e) => {
    setCardDetail({ ...cardDetail, [e.target.name]: e.target.value });
  };
  const onSubmitForm = (e) => {
    e.preventDefault();
    // console.log(cardDetail);
  };
  const [promocode, setPromocode] = useState("");
  const onHandlePromo = (e) => {
    setPromocode(e.target.value);
    // console.log(promocode);
  };
  return (
    <>
      <div className="main-container">
        <div className="main-wrapper">
          <div className="main-content payment-option-banner">
            <div className="payment-section-wrapper">
              <form className="pay-option-form">
                <div className="first-col">
                  <div className="payment-options-section">
                    <div className="payment-modes show-modes">
                      <div
                        className="tab-main-container vertical-tab-container"
                        style={{ minHeight: "400px" }}
                      >
                        <div className="tabs-collection payment-options-container">
                          <div className="tab-collection-header title">
                            Payment option
                          </div>
                          {paymentOption.map((options) => {
                            return (
                              <div
                                className={`tab-one tab-option border-under-text ${
                                  toggle === options.id ? "active" : ""
                                }`}
                                data-tab="tab-1"
                                key={options.id}
                                onClick={() => onClickHandle(options.id)}
                              >
                                <span className="tab-icon">
                                  <Icon
                                    icon={options.iconDetail}
                                    color="#1728e7"
                                    width="24"
                                    height="24"
                                  />
                                </span>
                                {options.option}
                              </div>
                            );
                          })}
                        </div>
                        <div className="tab-content">
                          {toggle === "1" ? (
                            <div
                              className="debit-credit-card-section Tabcontent-bill current"
                              id="tab-1"
                            >
                              <div className="radios-wrapper">
                                <div className="radio__RadioContainer-sc-1vosvuu-4 ddHRUi new-card-container label-left">
                                  <label className="radio__LabelWrapper-sc-1vosvuu-0 inEpIw  ">
                                    <input
                                      className="radio__RadioWrapper-sc-1vosvuu-1 kMNlGX"
                                      type="radio"
                                      name="newCardSection"
                                      value="New Card"
                                    />
                                    <img
                                      className="radio__CustomIconWrapper-sc-1vosvuu-2 egKDDx custom-icon "
                                      id="radio-one"
                                      src={radio_inactive_neo_banking_2}
                                      alt="Unchecked Option"
                                    />
                                    <img
                                      className="radio__CustomIconWrapper-sc-1vosvuu-2 egKDDx custom-icon"
                                      id="radio-two"
                                      src={radio_active_neo_banking_2}
                                      alt="Checked Option"
                                    />
                                    <span className="radio__ContentWrapper-sc-1vosvuu-3 jWgjnN label-text">
                                      <div className="add-card-strip content-visible">
                                        <div className="add-card-title">
                                          Add New Card
                                        </div>
                                        <div className="add-card-expanded is-active">
                                          <div className="add-card-content ">
                                            <div className="card-number">
                                              <div className="input__InputContainerWrapper-sc-77palv-0 gxoua input-wrapper card-number-container ">
                                                <div className="input__InputContainer-sc-77palv-1 kjqNrV input-container">
                                                  <p className="input__InputContainerLabelWithoutAnimation-sc-77palv-3 jNKTSn input-label-without-animation ">
                                                    Card Number
                                                  </p>
                                                  <input
                                                    type="tel"
                                                    name="cardNumber"
                                                    id="cardNumber"
                                                    placeholder="Enter Card Number"
                                                    width="100%"
                                                    maxLength="23"
                                                    autoComplete="new-password"
                                                    className="input__InputWrapper-sc-77palv-4 gjZEHA"
                                                    value={cardNumber}
                                                    onChange={(e) =>
                                                      onChangeHandle(e)
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div className="card-brand-container">
                                                <img
                                                  className=""
                                                  src={cardssprite}
                                                  alt="Card brand logo"
                                                />
                                              </div>
                                            </div>
                                            <div className="card-detail">
                                              <div className="card-validity">
                                                <div className="input__InputContainerWrapper-sc-77palv-0 gxoua input-wrapper expiry-date-container ">
                                                  <div className="input__InputContainer-sc-77palv-1 kjqNrV input-container">
                                                    <p className="input__InputContainerLabelWithoutAnimation-sc-77palv-3 jNKTSn input-label-without-animation ">
                                                      Validity
                                                    </p>
                                                    <input
                                                      type="tel"
                                                      name="validity"
                                                      id="expiryDate"
                                                      placeholder="MM/YY"
                                                      width="100%"
                                                      maxLength="5"
                                                      autoComplete="new-password"
                                                      className="input__InputWrapper-sc-77palv-4 gjZEHA"
                                                      value={validity}
                                                      onChange={(e) =>
                                                        onChangeHandle(e)
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="card-cvv">
                                                <div className="input__InputContainerWrapper-sc-77palv-0 gxoua input-wrapper cvv-container ">
                                                  <div className="input__InputContainer-sc-77palv-1 kjqNrV input-container">
                                                    <p className="input__InputContainerLabelWithoutAnimation-sc-77palv-3 jNKTSn input-label-without-animation ">
                                                      C V V
                                                    </p>
                                                    <input
                                                      type="tel"
                                                      name="cvv"
                                                      id="cvv"
                                                      placeholder="---"
                                                      width="100%"
                                                      maxLength="4"
                                                      autoComplete="new-password"
                                                      className="input__InputWrapper-sc-77palv-4 gjZEHA"
                                                      value={cvv}
                                                      onChange={(e) =>
                                                        onChangeHandle(e)
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="notification-section "></div>
                                          </div>
                                        </div>
                                      </div>
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <div className="card-info">
                                <span className="note">
                                  Ensure your card can be used for online
                                  payments.{" "}
                                </span>
                                <span className="know-more">Know More</span>
                              </div>
                              <button
                                onClick={(e) => onSubmitForm(e)}
                                className="button__PrimaryButtonWrapper-ah8457-0 hyYcxz submit-payment-option-btn"
                                width="100%"
                                type=""
                                disabled=""
                              >
                                Pay Now
                              </button>
                            </div>
                          ) : (
                            <div
                              className="net-banking-section Tabcontent-bill current"
                              id="tab-2"
                            >
                              <div className="card-content-title">
                                All Banks
                              </div>
                              <div className="radios-wrapper">
                                <div className="radio__RadioContainer-sc-1vosvuu-4 ddHRUi netbanking-container label-left">
                                  {allBanks.map((banks) => {
                                    return (
                                      <label
                                        className="radio__LabelWrapper-sc-1vosvuu-0 inEpIw"
                                        key={banks.id}
                                      >
                                        <input
                                          className="radio__RadioWrapper-sc-1vosvuu-1 custom-icon"
                                          type="radio"
                                          name="netBankingSection"
                                          value="HDFC"
                                        />
                                        <span className="radio__ContentWrapper-sc-1vosvuu-3 jWgjnN label-text">
                                          <div className="bank-details ">
                                            <img
                                              className="bank-image"
                                              src={banks.icon}
                                              alt=""
                                            />
                                            <span className="bank-name">
                                              {banks.bankName}
                                            </span>
                                          </div>
                                        </span>
                                      </label>
                                    );
                                  })}
                                </div>
                              </div>
                              <div className="net-banking-view-all">
                                <Link to="#" className="view-more">
                                  View All Banks
                                </Link>
                              </div>
                              <button
                                className="button__PrimaryButtonWrapper-ah8457-0 hyYcxz submit-payment-option-btn"
                                width="100%"
                                type=""
                                disabled=""
                              >
                                Pay Now
                              </button>
                            </div>
                          )}
                          <div className="options-disabled-info">
                            Some of the payment instruments are not eligible for
                            this transaction
                          </div>
                        </div>
                      </div>
                      <div className="payment-submit-wrap false"></div>
                    </div>
                  </div>
                </div>
                <div className="second-col">
                  <div className="electricity payment-head-section">
                    <div className="payment-details-section layout-4">
                      <div className="payment-details-data">
                        <label className="total-amount-label">
                          Total Amount
                        </label>
                        <div className="payment-details-first-row">
                          <div className="service-name">
                            <span>4,140.0</span>
                          </div>
                          <div className="action">
                            <p className="change-btn">change</p>
                          </div>
                        </div>
                        <div className="payment-details-bbps">
                          <img
                            className="avatar-img"
                            src={bbpslogo}
                            alt="service provider logo"
                          />
                        </div>
                        <div className="payment-details-second-row">
                          <div className="payment-details-avatar">
                            <img
                              className="avatar-img"
                              src={BSESRAJPLDEL01}
                              alt="service provider logo"
                            />
                          </div>
                          <div className="payment-details-content">
                            <div className="second-row-content">
                              <label>Biller Name</label>
                              <span>BSES Rajdhani</span>
                            </div>
                            <div className="second-row-content">
                              <label>CA Number</label>
                              <span>153887495</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="payment-details-wrap">
                    <div className="promo-code-section ">
                      <div className="input__InputContainerWrapper-sc-77palv-0 gxoua input-wrapper promo-code-container ">
                        <div className="input__InputContainer-sc-77palv-1 kjqNrV input-container">
                          <input
                            type="text"
                            name="promoCode"
                            placeholder="Have a promo code?"
                            width="100%"
                            autoComplete="new-password"
                            className="input__InputWrapper-sc-77palv-4 dkLYmB"
                            value={promocode}
                            onChange={(e) => onHandlePromo(e)}
                          />
                          <img
                            src="assets/img/promo-code-icon.png"
                            alt=""
                            className="input__PrefixIconWrapper-sc-77palv-5 jDbFPk"
                          />
                          <p className="input__SubmitWrapper-sc-77palv-8 jLNRYi">
                            <span className="apply-promo-code">Apply</span>
                          </p>
                        </div>
                      </div>
                      <p className="dashed-line "></p>
                    </div>
                    <div className="amount-details">
                      <h4>Amount Details</h4>
                      <div>
                        <p className="breakup-label">Base amount</p>
                        <p className="breakup-value">4,140.0</p>
                      </div>
                      <div className="total">
                        <label>Payable Amount</label>
                        <span>4,140.0</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pop-up-wrapper swipe-bottom top-rounded-layout">
                  <div
                    className="pop-up "
                    style={{
                      maxWidth: "400px",
                      maxHeight: " 450px",
                      minWidth: "400px",
                      minHeight: "auto",
                    }}
                  ></div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
