
import icon1 from "./../../assets/img/ASIA00000NATA2.webp";
import icon2 from "./../../assets/img/GTPL00000NATU2.webp";
import icon3 from "./../../assets/img/HATH00000NATRZ.webp";
import icon4 from "./../../assets/img/INDI00000NAT78.webp";
import icon5 from "./../../assets/img/INTE00000NATDG.webp";
import icon6 from "./../../assets/img/SATE00000MAHY1.webp";
import icon7 from "./../../assets/img/SANG00000NATEN.webp";

export const cableTvRechargeData = 
    [
        { id: "1", title: "Asianet Digital", icon: icon1 },
        { id: "2", title: "GTPL Hathway Limited", icon: icon2 },
        { id: "3", title: "Activline Telecom Private Limited", icon: icon3 },
        { id: "4", title: "Air Connect", icon: icon4 },
        { id: "5", title: "AirJaldi - Rural Broadband ", icon: icon5 },
        { id: "6", title: "Airnet Networks", icon: icon6 },
        { id: "7", title: "Airtel Broadband", icon: icon7 },
        
    ];
