import React from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import "./ContactUs.css"
function ContactUs() {
    return <section>
        {/* main--container  */}
        <div className="main-wrapper border border-1 rounded-3 bg-white my-5">
            <div className="home-tab-wrapper ">
                <div className="grid-section">
                    <h2 className=" text-center border-bottom mb-5 py-4">Contact Us</h2>

                    <div className='w-75 m-auto p-5'>
                        <div className='row'>
                            <div className='col-lg-4 col-md-4 col-12'>
                                <Form.Group className="mb-3 " controlId="formBasicEmail">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control className='form-controls' type="email" placeholder="" />

                                </Form.Group>
                            </div>
                            <div className='col-lg-4 col-md-4 col-12'>
                                <Form.Group className="mb-3 " controlId="formBasicEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control className='form-controls' type="email" placeholder="" />

                                </Form.Group>
                            </div>
                            <div className='col-lg-4 col-md-4 col-12'>
                                <Form.Group className="mb-3 " controlId="formBasicEmail">
                                    <Form.Label>Mobile</Form.Label>
                                    <Form.Control className='form-controls' type="email" placeholder="" />

                                </Form.Group>
                            </div>

                        </div>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12 col-12'>
                                <Form.Label>Your Message</Form.Label>
                                <FloatingLabel
                                    controlId="floatingTextarea"
                                    label="Message"
                                    className="mb-3 "
                                >

                                    <Form.Control className='form-controls' as="textarea" placeholder="Leave a comment here" />
                                </FloatingLabel>
                            </div>

                            <div className='col-lg-6 col-md-6 col-12'>
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col address-data '>
                                <div className=''>
                                    <h5 className='mb-3'>
                                        Address :- &nbsp; &nbsp;&nbsp;&nbsp; L.B.Enterprises
                                    </h5>
                                    <strong>
                                        <p>E-2/1269-A, G/F kh. No.-67, Gali No. 12/2, Pushta-5 <br />
                                            Sonia Vihar, Near Greenday public School,<br />
                                            Delhi-110090</p>
                                    </strong>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {/* <!-- ====home-slider-section --> */}

            </div>
        </div>
    </section>
}

export default ContactUs
