import React from "react";

function ContaintFastag(props) {
  return (
    <>
      {props.aboutSection.fistHeading ? (
        <h1>
          <span style={{ color: "#d86c06" }}>
            {props.aboutSection.fistHeading}
          </span>
        </h1>
      ) : (
        <>
          <h2>{props.aboutSection.heading}</h2>
          {props.aboutSection.paragraph ? (
            <>
              <p>{props.aboutSection.paragraph}</p>
              <>
                {props.aboutSection.item.map((item) => {
                  if (item.listParagraph) {
                    return <p key={item.id}>{item.listParagraph}</p>;
                  } else {
                    return (
                      <p key={item.id}>
                        <a href="#">{item.listParagraphA}</a>
                      </p>
                    );
                  }
                })}
              </>
            </>
          ) : (
            <>
              {props.aboutSection.item ? (
                <div
                  className="list-data"
                  itemScope
                  itemType="http://schema.org/FAQPage"
                >
                  {props.aboutSection.item.map((item) => {
                    return (
                      <React.Fragment key={item.id}>
                        <div
                          className="list-item "
                          itemScope
                          itemProp="mainEntity"
                          itemType="http://schema.org/Question"
                        >
                          <div className="faq-question">
                            <h3>{item.head}</h3>
                          </div>
                          <div
                            className="faq-answer"
                            itemProp="acceptedAnswer"
                            itemScope
                            itemType="http://schema.org/Answer"
                          >
                            <p>{item.listParagraph}</p>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              ) : (
                <>
                  {props.aboutSection.itemBenefits ? (
                    props.aboutSection.itemBenefits.map((item) => {
                      return (
                        <React.Fragment>
                          <h3>{item.head}</h3>
                          <p>{item.listParagraph}</p>
                          {item.listParagraph2 ? (
                            <p>{item.listParagraph2}</p>
                          ) : (
                            <></>
                          )}
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
      {props.aboutSection.paragraphs ? (
        props.aboutSection.paragraphs.map((item) => {
          return <p key={item.id}>{item.paragraph}</p>;
        })
      ) : (
        <></>
      )}
    </>
  );
}
export default ContaintFastag;
