

function InputSection() {
    const electricityImg = "electricity-header.svg"
    const billImg = "bill.jpg"
    const searchImg = "search-icon.png"
    const bulbImg = "electricity.webp"
   
    return <div className="search-layout new-layout">
        <div className="desktop-operators-main-header review-bill-header">
            <div className="desktop-heading-image"><img className="heading-image" src={require(`../../assets/img/${electricityImg}`)} title="Electricity Bill Payment Done!" alt="Electricity Bill Payment Done!" />
            </div>
            <div className="desktop-header-text">
                <div className="main-heading">Let's Get Your</div>
                <div className="sub-heading">Electricity Bill Payment Done!</div>
            </div><img  src={require(`../../assets/img/${billImg}`)} title="Electricity Bill Payment Done!" alt="Electricity Bill Payment Done!" />
        </div>

        <div className="search-layout-section search-input-wrapper  sticky">
            <div className="desktop-search-heading">Search</div>
            <div className="search-layout-field-wrapper ">
                <div className="search-layout-field-icon"><img src={require(`../../assets/img/${searchImg}`)} alt="search field icon" /></div>
                <div className="input__InputContainerWrapper-sc-77palv-0 gxoua input-wrapper search-layout-input-wrapper ">
                    <div className="input__InputContainer-sc-77palv-1 kjqNrV input-container"><input type="text" name="search-field" placeholder="Search" width="100%" autoComplete="off" className="input__InputWrapper-sc-77palv-4 ldAVAW" value="" onChange={() => { }} /></div>
                </div>
            </div>
        </div>

        <div className="search-layout-collapsible-section  collapsed" style={{ maxHeight: 100 + "%" }}>
            <div className="search-layout-section">
                <div className="slider-wrapper offers-slider-wrapper">
                    <div className="slider-track">
                        <div className="slider-section">
                            <div className="slider offers-slider" style={{ overflowX: "auto" }}>
                                <div data-index="0" className="slide active-slide" tabIndex="-1" style={{width: 367 + "px"}}>
                                    <div className="offers-card">
                                        <div className="offers-image-desc">
                                            <div className="img-div"><img src={require(`../../assets/img/${bulbImg}`)} alt="Offer Logo" /></div>
                                            <div className="desc-div">
                                                <p className="desc-head">Upto ₹30 CB</p>
                                                <p className="desc-text">Get upto Rs.30 cashback on
                                                    Electricity Bill payments on min txn of Rs.200.
                                                    For all users. Use code-POW30.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-index="1" className="slide" tabIndex="-1" style={{width: 367 + "px"}}>
                                    <div className="offers-card">
                                        <div className="offers-image-desc">
                                            <div className="img-div"><img src={require(`../../assets/img/${bulbImg}`)} alt="Offer Logo" /></div>
                                            <div className="desc-div">
                                                <p className="desc-head">Flat ₹40 CB</p>
                                                <p className="desc-text">Flat Rs.40 cashback on
                                                    Electricity Bill payments on min txn of
                                                    Rs.200.For new users only.Use code-POWNEW.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pop-up-wrapper popup-container swipe-bottom top-rounded-layout">
                    <div className="pop-up "style={{ maxHeight: "auto",minWidth: "auto" }}>
                        <div className="offer-popup-container"></div>
                    </div>
                </div>
            </div>
            <div className="location-info-box ">
                <div>
                    <p className="heading">Grant Location Access</p><span className="desc">Allow Location
                        access to show up your nearby service providers</span>
                </div>
                <p className="cta">ALLOW</p>
            </div>
        </div>

    </div>
}
export default InputSection