import waterMockData from "../../pages/water/MockData";
import waterTap from "./../../assets/img/water-header.svg";
import waterBoat from "./../../assets/img/water.webp";
import search_icon from "./../../assets/img/search-icon.png";
import { GroupOperatorTile } from "../common/groupOperatorTile/GroupOperatorTile";
import { AboutSection } from "../common/aboutSection/AboutSection";
export const WaterApp = () => {
  return (
    <>
      <main id="home_page">
        <div className="main-container" style={{ background: "transparent" }}>
          <div className="main-wrapper">
            <div className="main-content color-banner w-80">
              <div className="search-layout new-layout">
                <div className="desktop-operators-main-header review-bill-header">
                  <div className="desktop-heading-image">
                    <img
                      className="heading-image"
                      src={waterTap}
                      title="Water Bill Payment Done!"
                      alt="Water Bill Payment Done!"
                    />
                  </div>
                  <div className="desktop-header-text">
                    <div className="main-heading">Let's Get Your</div>
                    <div className="sub-heading">Water Bill Payment Done!</div>
                  </div>
                  <img
                    src={waterBoat}
                    title="Water Bill Payment Done!"
                    alt="Water Bill Payment Done!"
                  />
                </div>
                <div className="search-layout-section search-input-wrapper  sticky">
                  <div className="desktop-search-heading">Search</div>
                  <div className="search-layout-field-wrapper ">
                    <div className="search-layout-field-icon">
                      <img src={search_icon} alt="search field icon" />
                    </div>
                    <div className="input__InputContainerWrapper-sc-77palv-0 gxoua input-wrapper search-layout-input-wrapper ">
                      <div className="input__InputContainer-sc-77palv-1 kjqNrV input-container">
                        <input
                          type="text"
                          name="search-field"
                          placeholder="Search"
                          width="100%"
                          className="input__InputWrapper-sc-77palv-4 ldAVAW"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="search-layout-collapsible-section  collapsed">
                  <div className="search-layout-section"></div>
                  <div className="location-info-box ">
                    <div>
                      <p className="heading">Grant Location Access</p>
                      <span className="desc">
                        Allow Location access to show up your nearby service
                        providers
                      </span>
                    </div>
                    <p className="cta">ALLOW</p>
                  </div>
                </div>
                <div className="search-layout-section providers-list">
                  <div className="section-head">All Providers</div>
                  <div className="search-tile-wrapper ">
                    {waterMockData.waterData.map((item) => {
                      return (
                        <div>
                          <div key={item.id} className="group-head">
                            {item.state}
                          </div>
                          <div className="grouped-operator-tiles">
                            {item.waterServices.map((item) => {
                              return <GroupOperatorTile data={item} />;
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="about-section">
                    {<AboutSection data={waterMockData}/>}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
