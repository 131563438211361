import React from "react";
import { PostpaidBillPaymentData } from "../../pages/postpaidBillPayment/MockData"
export const AboutSection = () => {
  const dataStore = PostpaidBillPaymentData;
  return (
    <div className="about-section">
      <div className="content-section">
        {dataStore.map((item) => {
          if (item.bottomDataHeader) {
            return (
              <React.Fragment key={item.id}>
                <h2>{item.bottomDataHeader}</h2>
                {item.data.map((item) => {
                  return (
                    <React.Fragment key={item.id}>
                      <h3>
                        <span>{item.id}</span>.{item.question}
                      </h3>
                      <p>{item.answer}</p>
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            );
          } else {
            return (
              <div key={item.id}>
                {item.topData ? (
                  item.topData.map((item) => {
                    return (
                      <React.Fragment key={item.id}>
                        <h1>{item.title}</h1>
                        {item.pargraph.map((item) => {
                          return (
                            <React.Fragment key={item.id}>
                              <p>{item.para}</p>
                            </React.Fragment>
                          );
                        })}
                      </React.Fragment>
                    );
                  })
                ) : (
                  <></>
                )}
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};
