import React from "react";
import { RentPayment } from "../../components/rentPayment/RentPayment";

const RentpaymentPage = () => {
  return (
    <div>
      <RentPayment />
    </div>
  );
};

export default RentpaymentPage;
