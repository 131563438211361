import React from 'react'
import TermConditions from '../../components/Term&Condition/Term&conditions'

function TermsAndConditions() {
    return (
        <>
            <TermConditions />
        </>
    )
}

export default TermsAndConditions

