import React from 'react'
import { mockData } from './MockData'
import "./TermsAndConditions.css"

function TermConditions() {
    return (
        <section>
            {/* main--container  */}
            <div className="main-wrapper border border-1 rounded-3 bg-white my-5">
                <div className="home-tab-wrapper ">
                    <div className="grid-section">
                        <h2 className=" text-center border-bottom mb-5 py-4">Terms And Conditions</h2>

                        <div className='w-75 m-auto p-5'>
                            <ol>
                                {mockData.map((item) => {
                                    return <li className='list-style'>

                                        <p className='mb-2'>{item.para}</p>
                                    </li>
                                })}

                            </ol>
                        </div>

                    </div>
                    {/* <!-- ====home-slider-section --> */}

                </div>
            </div>
        </section>
    )
}

export default TermConditions
