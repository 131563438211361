import googleplayICon from "./../../assets/img/google_play_2.png";
// ...slider-icons
import icon1 from "./../../assets/img/pay-later.png";
import icon2 from "./../../assets/img/mutual-funds.png";
import icon3 from "./../../assets/img/vcc.png";
import icon4 from "./../../assets/img/gold.svg";

export const HomeData = {
  paymentOptions: [
    {
      id: "1",
      title: "Rent Payment",
      path: "/rent-payment",
      icon: <i className="bx bx-home-alt"></i>,
    },
    {
      id: "2",
      title: "Mobile Postpaid",
      path: "/postpaid-bill-payment",
      icon: <i className="bx bx-mobile-alt"></i>,
    },
    {
      id: "3",
      title: "Rent via Credit Card",
      path: "/rent-credit",
      icon: <i className="bx bxs-credit-card"></i>,
    },
    {
      id: "4",
      title: "Electricity",
      path: "/electricity",
      icon: <i className="bx bx-bulb"></i>,
    },
    {
      id: "5",
      title: "DTH",
      path: "/dth",
      icon: <i className="bx bx-dish"></i>,
    },
    {
      id: "6",
      title: "FASTag Recharge",
      path: "/fastag-recharge",
      icon: <i className="bx bxs-car-garage"></i>,
    },
    {
      id: "7",
      title: "Book a Cylinder",
      path: "/book-cylinder",
      icon: <i className="bx bx-cylinder"></i>,
    },
    {
      id: "8",
      title: "Landline",
      path: "/landline",
      icon: <i className="bx bx-phone"></i>,
    },
    {
      id: "9",
      title: "Gas",
      path: "/gas-bill-payment",
      icon: <i className="bx bx-gas-pump"></i>,
    },
    {
      id: "10",
      title: "Water",
      path: "/water-bill-payment",
      icon: <i className="bx bx-water"></i>,
    },
    {
      id: "11",
      title: "Broadband",
      path: "/broadband",
      icon: <i className="bx bx-radio"></i>,
    },
    {
      id: "12",
      title: "OTT/Subscriptions",
      path: "/ott-subscription",
      icon: <i className="bx bx-movie-play"></i>,
    },
    {
      id: "13",
      title: "Loan Repayment",
      path: "/loan-repayment",
      icon: <i className="bx bx-terminal"></i>,
    },
    {
      id: "14",
      title: "Google Play",
      path: "/googleplay",
      icon: (
        <img
          src={googleplayICon}
          alt="googleplay"
          style={{ width: "-webkit-fill-available" }}
        />
      ),
    },
    {
      id: "15",
      title: "Insurance",
      path: "/insurance",
      icon: <i className="bx bx-check-shield"></i>,
    },
    {
      id: "16",
      title: "Municipal Tax",
      path: "/tax-payment",
      icon: <i className="bx bx-money-withdraw"></i>,
    },
    {
      id: "17",
      title: "Apartments",
      path: "/apartments",
      icon: <i className="bx bx-rupee"></i>,
    },
    {
      id: "18",
      title: "Cable TV",
      path: "/cable-tv-recharge",
      icon: <i className="bx bx-cable-car"></i>,
    },
    {
      id: "19",
      title: "Education Fees",
      path: "/education",
      icon: <i className="bx bx-donate-heart"></i>,
    },
  ],
  sliderData: [
    {
      id: 1,
      title: "Pay Later",
      sub_title: "Get instant interest-free credit",
      icon: icon1,
      bgClass: "bg-color-1",
    },
    {
      id: 2,
      title: "Mutual Funds",
      sub_title: "Kickstart your future",
      icon: icon2,
      bgClass: "bg-color-2",
    },
    {
      id: 3,
      title: "Credit Card",
      sub_title: "Instant Approval, Unlimited Cashback",
      icon: icon3,
      bgClass: "bg-color-3",
    },
    {
      id: 4,
      title: "Gold",
      sub_title: "Pure, Safe, and Convenient",
      icon: icon4,
      bgClass: "bg-color-1",
    },
  ],
};
