import React from "react";
import GasPayment from "../../components/gasPayment/GasPayment";

const GasPaymentPage = () => {
  return (
    <div>
      <GasPayment />
    </div>
  );
};

export default GasPaymentPage;
