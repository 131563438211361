import React from 'react';

import CableTvRecharge from "../../components/cableTvRecharge/cableTvRecharge"
function cableTvRechargePage() {
    
    return (
        <>
            <main id="cableTvRecharge">
                <div className="main-container">
                    <div className="main-wrapper">
                        <CableTvRecharge />
                    </div>
                </div>
            </main>
        </>
    )
}

export default cableTvRechargePage