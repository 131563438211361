import React from 'react'
import ContactUs from '../../components/ContactUs/ContactUs'

function ContactUspage() {
    return (
        <>
            <ContactUs />
        </>
    )
}

export default ContactUspage
