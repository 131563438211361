function ContaintPayment(props) {
  return (
    <>
      <h1>{props.aboutSection.heading}</h1>
      {props.aboutSection.paragraphs.map((item) => {
        console.log(item);
        if (item.data) {
          return (
            <>
              <p>{item.topPara}</p>

              {item.data.map((item) => {
                return (
                  <>
                    <p>
                      <strong>{item.strong}</strong>
                    </p>
                    <p>{item.paragraph}</p>
                  </>
                );
              })}
            </>
          );
        }
        if (item.lists) {
          return (
            <>
              <p>{item.topPara}</p>
              <ol>
                {item.lists.map((item) => {
                  return <li key={item.id}>{item.list}</li>;
                })}
              </ol>
            </>
          );
        } else {
          return (
            <>
              {item.heading ? (
                <>
                  <h2>{item.heading}</h2>
                </>
              ) : (
                <></>
              )}
              <p>{item.paragraph}</p>
              <></>
            </>
          );
        }
      })}
    </>
  );
}
export default ContaintPayment;
