import { OperatorTile } from "../common/operatorTile/OperatorTile";
import broadBandMockData from "./../../pages/broadBand/MockData";

function BroadBandApp() {
  return (
    <>
      <main id="broadband_page">
        <div className="main-container" style={{ background: "transparent" }}>
          <div className="main-wrapper">
            <div className="main-content color-banner w-80">
              <div className="search-layout new-layout">
                <div
                  className="search-layout-collapsible-section  collapsed"
                  style={{ maxHeight: 100 + "%" }}
                >
                  <div className="search-layout-section"></div>
                </div>
                <div className="search-layout-section providers-list">
                  <div className="section-head">All Providers</div>
                  <div className="all-providers-tile">
                    {broadBandMockData.map((item) => {
                      return <OperatorTile data={item} key={item.id} />;
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
export default BroadBandApp;
