import { mockData } from "../../../pages/taxPayment/MockData";

function AllProvider(props) {
  return (
    <div className="search-tile-wrapper">
      <div className="group-head">{props.allprovider.state}</div>
      <div className="grouped-operator-tiles">
        {props.allprovider.offices.map((item, i) => {
          return (
            <div key={i} className="operator-tile  flex-center">
              <div className="logo-container">
                <img
                  src={item.url}
                  alt="Operator Logo"
                  className="operator-img"
                />
              </div>
              <div className="operator-content">
                <div className="operator-name">{item.title}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default AllProvider;
