import React, { useState } from "react";
import sb_icon from "../../assets/img/sb_icon.svg";
import sb from "../../assets/img/sb.svg";
import searchicon from "../../assets/img/search-icon.png";
import { subscriptionsData } from "../../pages/subscriptions/mockData";
import { OperatorTile } from "../common/operatorTile/OperatorTile";

export const Subscriptions = () => {
  const [selectedData, setSelectedData] = useState(
    subscriptionsData.operatorTileData
  );
  const onHandleChange = (e) => {
    if (e.target.value === "") {
      setSelectedData(subscriptionsData.operatorTileData);
    } else {
      setSelectedData(
        subscriptionsData.operatorTileData.filter((data) => {
          return data.title
            .toLowerCase()
            .includes(e.target.value.toLowerCase());
        })
      );
    }
  };
  return (
    <div className="main-container">
      <div className="main-wrapper">
        <div className="main-navigation">
          <div className="navigation-list  navigation-primary">
            <div className="list-track">
              <div className="primary-list">
                <ul>
                  <li className="active-option">
                    <span className="label-text" title="Home">
                      Home
                    </span>
                  </li>
                  <li className="">
                    <span className="label-text" title="Loans">
                      Loans
                    </span>
                  </li>
                  <li className="list-divider"></li>
                  <li className="">
                    <span className="label-text" title="Offers">
                      Offers
                    </span>
                  </li>
                  <li className="">
                    <span className="label-text" title="Help and Support">
                      Help and Support
                    </span>
                  </li>
                </ul>
              </div>
              <div className="secondary-list">
                <label className="back-label">
                  <img
                    src="assets/img/back.png"
                    alt="sidebar back icon"
                    loading="lazy"
                  />
                  back
                </label>
                <h6>More Option</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="main-content color-banner w-80">
          <div className="search-layout new-layout">
            <div className="desktop-operators-main-header review-bill-header">
              <div className="desktop-heading-image">
                <img
                  className="heading-image"
                  src={sb_icon}
                  title="Subscriptions Payment Done!"
                  alt="Subscriptions Payment Done!"
                />
              </div>
              <div className="desktop-header-text">
                <div className="main-heading">Let's Get Your</div>
                <div className="sub-heading">Subscriptions Payment Done!</div>
              </div>
              <img
                src={sb}
                title="Subscriptions Payment Done!"
                alt="Subscriptions Payment Done!"
              />
            </div>
            <div className="search-layout-section search-input-wrapper  sticky">
              <div className="desktop-search-heading">Search</div>
              <div className="search-layout-field-wrapper ">
                <div className="search-layout-field-icon">
                  <img src={searchicon} alt="search field icon" />
                </div>
                <div className="input__InputContainerWrapper-sc-77palv-0 gxoua input-wrapper search-layout-input-wrapper ">
                  <div className="input__InputContainer-sc-77palv-1 kjqNrV input-container">
                    <input
                      type="text"
                      name="search-field"
                      placeholder="Search"
                      width="100%"
                      autoComplete="off"
                      className="input__InputWrapper-sc-77palv-4 ldAVAW"
                      onChange={(e) => onHandleChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="search-layout-collapsible-section  collapsed"
              style={{ maxHeight: "100%" }}
            >
              <div className="search-layout-section"></div>
            </div>
            <div className="search-layout-section providers-list">
              <div className="section-head">All Providers</div>
              <div className="all-providers-tile">
                {selectedData.map((data) => {
                  return <OperatorTile data={data} key={data.id} />;
                })}
              </div>
            </div>
          </div>
          <div className="about-section">
            <div className="content-section" style={{ maxHeight: "100%" }}>
              <h1>Bill Payments for OTT Platforms via Abaris Payment </h1>
              <h2>What is an OTT Platform?</h2>
              <p>
                OTT, the abbreviation of "Over-the-Top"- refers to a modern-day
                technological method of serving movie and television content
                delivery online.
              </p>
              <p>
                It enables the viewers' to stream these contents at any time as
                per their convenience. The modern age OTT platforms take away
                the requirements of conventional broadcasting cable connections.
              </p>
              <p>
                OTT also renders the satellite pay-TV providing services
                redundant since the viewers may stream content online from any
                device using their internet connectivity sources.
              </p>
              <p>
                One can get rid of overwhelming cable bills per month by taking
                an OTT subscription.
              </p>
              <h2>The Best OTT Platforms' Delivery Services</h2>
              <p>
                The nationwide accessibility feature makes the best OTT
                Platforms in India deserve every bit of its hype. The top OTT
                platforms in India do not take anything except for a stable
                internet connection to access.{" "}
              </p>
              <p>
                Any electronic device that can load browsers and OTT
                applications can load these shows through the internet.
              </p>
              <h2>Devices Compatible with OTT Platforms</h2>
              <p>
                Now, you can install the OTT apps from any smartphone or tablet.
                After downloading the app, sign up on the apps to access the
                contents.
              </p>
              <p>
                <strong>Personal Computers</strong>
              </p>
              <p>
                Desktop-based applications help load OTT content on your
                computer devices. Web browsers are very easy to use. Get the
                list of OTT platforms in India at the snap of a button online.
              </p>
              <p>
                <strong>Smart TVs Fuelling OTT India</strong>
              </p>
              <p>
                The options of a Smart tv list are endless for OTT content
                watch. Apple tv, Firestick is the best among Smart TVs.{" "}
              </p>
              <p>
                Indian OTT platforms are growing by leaps and bounds with the
                help of all these devices.
              </p>
              <h2>What are the Free OTT Platforms in India?</h2>
              <h3>Netflix</h3>
              <p>
                The OTT giant Netflix dominates the OTT platforms in India and
                worldwide. It serves a chunk of blockbuster, original series to
                satiate the entertainment needs of more than 200 million
                viewers. The postpaid network plans for Jio and vi enable the
                customers to access Netflix's quality content.
              </p>
              <p>
                Jio offers postpaid plans at varied prices ranging from 399,599
                to 1499.
              </p>
              <p>
                Another way to subscribe to Netflix in lesser amounts is to
                divide the subscription price among family members or friends.
              </p>
              <h3>Amazon Prime Video</h3>
              <p>
                The one-month free trial of prime video lets one enjoy its
                top-notch content for one whole month. And then, you got to
                decide whether to continue with the subscription or not.{" "}
              </p>
              <p>
                On the computer device, the three-line icon on the top left
                corner may take one to help button through the Amazon account.
                Then, following the member and subscription option, tap on the '
                Free Time Unlimited.'
              </p>
              <p>
                Hence, the viewers get provided with all the plan options. Pick
                one as per the preference, and the free trial period starts
                henceforth.
              </p>
              <h3>Zee5 OTT Platform</h3>
              <p>
                Even though officially, zee5 Ott does not cater to any service
                free of cost. The viewers may get hold of all the
                non-premium-free movies, series, and television shows on the
                application.{" "}
              </p>
              <p>
                The{" "}
                <a
                  href="https://www.Abaris Payment.in/subscriptions/zee5"
                  target="_self"
                >
                  Zee5 monthly subscription
                </a>{" "}
                options range from 99 onwards. The yearly subscription costs
                around 999.
              </p>
              <h3>Disney+ Hotstar</h3>
              <p>
                The OTT platform Hotstar offers a bucket of non-premium free
                shows and movie content online.{" "}
              </p>
              <p>
                In between, a bit of advertisement interrupts the watching
                experience anyway. The monthly subscription to the OTT Hotstar
                costs around 299. Hotstar's yearly subscription is 1499 every
                year.{" "}
              </p>
              <p>
                There is also a VIP variant subscription plan on Hotstar. One
                can acquire this plan by investing 365 per year.
              </p>
              <h2>Renew OTT Subscription on Abaris Payment</h2>
              <p>
                For ott subscription renewal through Abaris Payment, a customer
                needs to enter the Abaris Payment app by signing up there. After
                that, tap through the payment gateway for making payments for
                the ott subscription.{" "}
              </p>
              <p>
                One may also get these options on any browser. Then, tap on the
                OTT platforms you wish to get subscribed with for the OTT
                renewal.{" "}
              </p>
              <p>
                While making payments from the browser, one must put the mobile
                number for the subscription process.{" "}
              </p>
              <p>
                And choosing the duration of the subscription, one may choose to
                pay the subscription in whichever payment mode suits one's
                preferences.
              </p>
              <h2>
                Frequently Asked Questions on OTT Subscriptions Via Abaris
                Payment{" "}
              </h2>
              <h3>
                Abaris Payment offers the payment gateway for which OTT
                subscriptions?
              </h3>
              <p>
                Abaris Payment offers the payment gateway for zee5 OTT
                subscriptions.
              </p>
              <h3>How to watch the OTT platforms on a mobile device? </h3>
              <p>
                For watching OTT platforms on Mobile, download the respective
                OTT applications straight up from the play store in no time.{" "}
              </p>
              <p>
                Then, sign up on the OTT application as per your choice. Choose
                the subscription plan that suits the requirements pretty well.{" "}
              </p>
              <h3>What are the affordable OTT platforms to opt-in to India?</h3>
              <p>
                The most affordable OTT platform to opt-in to India is zee5 OTT.
                Here, one gets the monthly subscription at 99.
              </p>
              <h3>How do I get an OTT platform for free?</h3>
              <p>
                The non-premium content on OTT platforms like zee5, Hotstar is
                free to watch. Hence, download these applications from Play
                Store and enjoy OTT free watch.
              </p>
              <p></p>
              <p></p>
              <p></p>
              <p></p>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
