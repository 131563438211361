import React, { useState } from "react";
import { rentPaymentData } from "../../pages/rentPayment/mockData";

import basic from "../../assets/img/basic.svg";
import { Link } from "react-router-dom";
export const RentPayment = () => {
  const [check, setCheck] = useState("check1");
  const [accountdetail, setAccountdetail] = useState({
    accountNumber: "",
    ifscCode: "",
    name: "",
    mobile: "",
  });
  const [upiDetail, setUpiDetail] = useState({
    upiId: "",
    mobileUpi: "",
  });
  const { accountNumber, ifscCode, name, mobile } = accountdetail;
  const { upiId, mobileUpi } = upiDetail;
  const onCheckHandle = (e) => {
    setCheck(e.target.value);
  };
  const onAccountFormHandleChange = (e) => {
    setAccountdetail({ ...accountdetail, [e.target.name]: e.target.value });
  };
  const onAccountFormSubmitForm = () => {
    // console.log(accountdetail);
  };
  const onUpiFormHandleChange = (e) => {
    setUpiDetail({ ...upiDetail, [e.target.name]: e.target.value });
  };
  const upiFormSubmit = () => {
    // console.log(upiDetail);
  };
  const [cost, setCost] = useState(4000);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const onClickPaymentCategory = (category, index) => {
    setCost(category.cost);
    setSelectedIndex(index);
  };
  return (
    <>
      <div className="main-container bg-rent-wrapper">
        <div className="main-wrapper">
          <div className="main-navigation">
            <div className="navigation-list  navigation-primary">
              <div className="list-track">
                <div className="primary-list">
                  <ul>
                    <li className="active-option">
                      <span className="label-text" title="Home">
                        Home
                      </span>
                    </li>
                    <li className="">
                      <span className="label-text" title="Loans">
                        Loans
                      </span>
                    </li>
                    <li className="list-divider"></li>
                    <li className="">
                      <span className="label-text" title="Offers">
                        Offers
                      </span>
                    </li>
                    <li className="">
                      <span className="label-text" title="Help and Support">
                        Help and Support
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="secondary-list">
                  <label className="back-label">
                    <img
                      src="assets/img/back.png"
                      alt="sidebar back icon"
                      loading="lazy"
                    />
                    back
                  </label>
                  <h6>More Option</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="main-content rent-card">
            <div className="rent-payment-card-box shadow-sm">
              <div className="card-content-rent">
                <div className="heading">
                  <h3 className="section-heading">Rent Payments</h3>
                  <p>Or Prepaid / Debit Card, Paytm Wallet or Postpaid</p>
                </div>
                <div className="tab-checkbox">
                  <div className="tab-check">
                    <div className="form-check list-item active" data-tab="one">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        defaultChecked={true}
                        value="check1"
                        onChange={(e) => onCheckHandle(e)}
                      />
                      <label
                        className="form-check-label ps-2"
                        htmlFor="flexRadioDefault1"
                      >
                        To Bank A/c
                      </label>
                    </div>
                    <div className="form-check list-item" data-tab="two">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        defaultChecked={false}
                        value="check2"
                        onChange={(e) => onCheckHandle(e)}
                      />
                      <label
                        className="form-check-label ps-2"
                        htmlFor="flexRadioDefault2"
                      >
                        To UPI ID
                      </label>
                    </div>
                  </div>
                  {check === "check1" ? (
                    <div
                      className="Tabcontent active position-relative"
                      id="one"
                    >
                      <div className="tab-form-list position-relative">
                        <ul>
                          <li>
                            <input
                              className="form-control tab-form"
                              required
                              type="password"
                              placeholder="Bank Account Number"
                              name="accountNumber"
                              value={accountNumber}
                              title="password"
                              onChange={(e) => onAccountFormHandleChange(e)}
                            />
                          </li>
                          <li>
                            <input
                              className="form-control tab-form position-relative"
                              required
                              type="text"
                              placeholder="IFSC Code"
                              name="ifscCode"
                              value={ifscCode}
                              title="IFSC Code"
                              onChange={(e) => onAccountFormHandleChange(e)}
                            />
                            <Link to="#">Find IFSC</Link>
                          </li>
                          <li>
                            <input
                              className="form-control tab-form"
                              required
                              type="text"
                              placeholder="Account Holder's Name"
                              name="name"
                              value={name}
                              title="Account Holder's Name"
                              onChange={(e) => onAccountFormHandleChange(e)}
                            />
                            <div className="required">
                              This name will be shown in your saved accounts
                            </div>
                          </li>
                          <li>
                            <input
                              className="form-control tab-form"
                              required
                              type="tel"
                              placeholder="Mobile Number (Optional)"
                              name="mobile"
                              value={mobile}
                              title="Mobile Number (Optional)"
                              onChange={(e) => onAccountFormHandleChange(e)}
                            />
                          </li>
                        </ul>
                        <div className="proceed-btn mt-lg-4 mt-3">
                          <Link
                            to="/rent-payment/payment-option"
                            className="primary-proceed"
                            onClick={onAccountFormSubmitForm}
                          >
                            Proceed
                          </Link>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="Tabcontent active" id="two">
                      <div className="tab-form-list position-relative">
                        <ul>
                          <li>
                            <input
                              className="form-control tab-form"
                              required
                              type="text"
                              placeholder="Enter Recipient’s UPI ID"
                              name="upiId"
                              value={upiId}
                              title="Enter Recipient’s UPI ID"
                              onChange={(e) => onUpiFormHandleChange(e)}
                            />
                          </li>
                          <li className="mb-lg-4 mb-3">
                            <input
                              className="form-control tab-form"
                              required
                              type="text"
                              placeholder="Mobile Number (Optional)"
                              name="mobileUpi"
                              value={mobileUpi}
                              title="Mobile Number (Optional)"
                              onChange={(e) => onUpiFormHandleChange(e)}
                            />
                          </li>
                        </ul>

                        <div className="proceed-btn mt-lg-4 mt-3">
                          <Link
                            to="/rent-payment/payment-option"
                            className="primary-proceed"
                            onClick={upiFormSubmit}
                          >
                            Proceed
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="fixed-wrap-card shadow-sm">
            <div className="fixed-card-content">
              <div className="fixed-header">
                <div className="left">
                  <img src={basic} alt="basic" />
                </div>
                <div className="right">
                  <span>Step 1 of 3</span>
                  <p>Start your payment</p>
                </div>
              </div>
              <div className="fixed-body">
                <section className="css-804o3t">
                  <div className="css-111px30">
                    <form className="form css-4ejps8">
                      <div className="form-item type-pill css-1ascwlj">
                        <div className="sel-cont css-g9xpsd">
                          <div className="sel-placeholder css-szbi6c">
                            Choose Payment category
                          </div>
                          <div className="options-wrap">
                            {rentPaymentData.paymentCategory.map(
                              (category, index) => {
                                return (
                                  <div
                                    key={category.id}
                                    className={`option css-5uj2yn ${
                                      selectedIndex === index ? "active" : ""
                                    }`}
                                    onClick={() =>
                                      onClickPaymentCategory(category, index)
                                    }
                                  >
                                    {category.svg}
                                    {category.rentCategory}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-item type-pill css-a802sj">
                        <div className="css-2jfdz5">
                          <input
                            required=""
                            readOnly=""
                            inputMode="numeric"
                            parse="[object Object]"
                            type="text"
                            className="inputStyle has-value css-xu01pc"
                            maxLength="10"
                            value={`₹ ${cost}`}
                            onChange={() => setCost(cost)}
                          />
                          <label className="undefined inputLabelStyle css-wfstwc">
                            Amount
                          </label>
                        </div>
                      </div>
                    </form>
                    <div className="css-gbfk11">
                      <button className="button default primary full-width css-82ihkd">
                        Next, add landlord details
                      </button>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
