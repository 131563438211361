export const mockData = [
  {
    id: 1,
    heading: "Abaris Payment",
    list: [
      { ïd: 1, title: "About Us", url: "" },
      { id: 2, title: "Support", url: "" },
      { id: 3, title: "Contact Us", url: "contact-us" },
      { id: 4, title: "Careers", url: "" },
      { id: 5, title: "Blog", url: "" },
      { id: 6, title: "Offers", url: "" },
      { id: 7, title: "Terms & Conditions", url: "terms-and-conditions" },
      { id: 7, title: "Refund Policy", url: "refund-policy" },
      { id: 7, title: "Privaicy Policy", url: "privacy-policy" },
      { id: 8, title: "Creditors Notice", url: "" },
      { id: 9, title: "Share Holder Notice", url: "" },
      { id: 10, title: "Remuneration Policy", url: "" },
      { id: 11, title: "CSR Policy", url: "" },
    ],
  },
  {
    id: 2,
    heading: "Financial Services",
    list: [
      { id: 1, title: "Abaris Payment Pay Later", url: "" },
      { id: 2, title: "Mutual Fund", url: "" },
      { id: 3, title: "Digital Gold", url: "" },
      { id: 4, title: "Pay Insurance Premium", url: "" },
      { id: 5, title: "Fixed Deposit", url: "" },
      { id: 6, title: "Credit Card", url: "" },
      { id: 7, title: "Loans", url: "" },
      { id: 8, title: "Loan Repayment", url: "" },
      { id: 9, title: "UPI", url: "" },
    ],
  },
  {
    id: 3,
    heading: "Popular Billers",
    list: [
      { id: 1, title: "Jio Mobile Recharge", url: "" },
      { id: 2, title: "Airtel Mobile Recharge", url: "" },
      { id: 3, title: "Vi Mobile Recharge", url: "" },
      { id: 4, title: "BSNL Mobile Recharge", url: "" },
      { id: 5, title: "Tata Play DTH Recharge", url: "" },
      { id: 6, title: "Airtel DTH Recharge", url: "" },
      { id: 7, title: "Paytm FASTag Recharge", url: "" },
      { id: 8, title: "BSES Rajdhani", url: "" },
      { id: 9, title: "BSNL Landline Bill Payment", url: "" },

    ],
  },
  {
    id: 4,
    heading: "Recharge & Pay Bills",
    list: [
      { id: 1, title: "Mobile Recharge", url: "postpaid-bill-payment" },
      { id: 2, title: "FASTag Recharge", url: "" },
      { id: 3, title: "Mobile Bill Payment", url: "" },
      { id: 4, title: "Google Play Recharge", url: "" },
      { id: 5, title: "Electricity North", url: "electricity" },
      { id: 6, title: "Electricity South", url: "electricity" },
      { id: 7, title: "DTH", url: "" },
      { id: 8, title: "Pay Rent Using Credit Card", url: "rent-credit" },

    ],
    list1: [
      { id: 1, title: "Piped Gas", url: "gas-bill-payment" },
      { id: 2, title: "Book a Cylinder", url: "" },
      { id: 3, title: "Water", url: "water-bill-payment" },
      { id: 4, title: "Municipal Tax", url: "tax-payment" },
      { id: 5, title: "Landline", url: "" },
      { id: 6, title: "Broadband", url: "" },

    ],
  },
]
