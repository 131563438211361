import React from "react";
import { Subscriptions } from "../../components/subscriptions/Subscriptions";

const OttSubscriptionsPage = () => {
  return (
    <div>
      <Subscriptions />
    </div>
  );
};

export default OttSubscriptionsPage;
