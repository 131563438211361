import hdfc from "../../assets/img/hdfc.webp";
import icici from "../../assets/img/icici.webp";
import citi from "../../assets/img/citi.webp";
import axis from "../../assets/img/axis.webp";

export const rentPaymentData = {
  paymentCategory: [
    {
      id: "1",
      rentCategory: "House Rent",
      cost: "4000",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="21"
          viewBox="0 0 21 21"
        >
          <g fill="#999" transform="translate(.5 .5)">
            <circle cx="6.318" cy="6.384" r="6.228" fillOpacity=".6"></circle>
            <path d="M13.651 4.492c.159-.154.4-.167.574-.044l.07.06 5.745 6.178c.058.062.096.14.112.222l.008.084v8.685c0 .222-.16.406-.371.443l-.082.007-4.737-.027c-.22-.001-.402-.16-.44-.37l-.007-.08v-.738c0-.249.201-.45.45-.45.22 0 .405.16.443.37l.007.08-.001.291 3.837.021v-8.056l-5.311-5.71-5.894 5.724v8.044l3.859-.004v-3.943c0-.22.16-.405.37-.443l.08-.007h2.61c.22 0 .405.16.443.37l.007.08v1.593c0 .249-.201.45-.45.45-.22 0-.405-.16-.443-.37l-.007-.08-.001-1.143h-1.71l.001 3.942c0 .22-.159.404-.369.443l-.08.007-4.758.006c-.222 0-.406-.159-.444-.369l-.007-.081v-8.685c0-.091.028-.18.078-.254l.058-.069 6.36-6.177z"></path>
          </g>
        </svg>
      ),
    },
    {
      id: "2",
      rentCategory: "Office Rent",
      cost: "5000",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="22"
          viewBox="0 0 21 22"
        >
          <g fill="none" fillRule="evenodd">
            <g>
              <g>
                <path
                  fill="#999"
                  fillOpacity=".4"
                  fillRule="nonzero"
                  d="M0 0.196H16V8.196H0z"
                  transform="translate(-145 -134) translate(145 134)"
                ></path>
                <path
                  stroke="#999"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M18.287 5.53l1.57 3.64c.513 1.188-.415 2.488-1.777 2.488h-.06c-1.059 0-1.917-.81-1.917-1.808V8.77v.976c0 1.056-.907 1.912-2.027 1.912h-.156c-1.12 0-2.028-.856-2.028-1.912V8.77v.976c0 1.056-.908 1.912-2.028 1.912s-2.028-.856-2.028-1.912V8.77v1.08c0 .998-.857 1.808-1.916 1.808-1.366 0-2.294-1.308-1.773-2.498l1.59-3.63h12.55zM5.333 13.529L5.333 21.515 12.322 21.515 12.322 14.798 16.306 14.798 16.306 17.909M19.006 13.529L19.006 21.515 16.339 21.515 16.339 19.944"
                  transform="translate(-145 -134) translate(145 134)"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      ),
    },
    {
      id: "3",
      rentCategory: "Security Deposit",
      cost: "2000",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
        >
          <g fill="none" fillRule="evenodd">
            <g>
              <g>
                <path
                  fill="#D6D6D6"
                  d="M0 2.067v3.419c0 1.78.752 3.479 2.071 4.672l3.12 2.825 2.954-2.84c1.232-1.188 1.928-2.828 1.928-4.542V2.067L5.192 0 0 2.067z"
                  transform="translate(-325 -134) translate(325 134)"
                ></path>
                <path
                  stroke="#999"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.263"
                  d="M7.86 7.59c2.952-3.044 7.731-3.044 10.683 0 2.953 3.044 2.952 7.972 0 11.016-2.952 3.044-7.731 3.044-10.683 0C5.867 16.551 5.222 13.618 5.925 11"
                  transform="translate(-325 -134) translate(325 134)"
                ></path>
                <g
                  stroke="#999"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.137"
                >
                  <path
                    d="M0 2.477L5.849 2.477M0 .065L5.849.065M1.38.057c1.46 0 2.633 1.188 2.633 2.667 0 1.478-1.173 2.666-2.633 2.666H0l4.094 3.714"
                    transform="translate(-325 -134) translate(325 134) translate(10.684 9.222)"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      ),
    },
    {
      id: "4",
      rentCategory: "Booking Token",
      cost: "3000",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="23"
          viewBox="0 0 22 23"
        >
          <g fill="none" fillRule="evenodd">
            <g>
              <g>
                <g stroke="#999" strokeLinecap="round" strokeLinejoin="round">
                  <path
                    d="M0 9.541L3.622 9.541 3.622 0 0 0M3.622 1.763L7.348.414 13.26 2.99c.659.288.975 1.044.717 1.716-.276.722-1.103 1.062-1.805.742L8.925 3.975"
                    transform="translate(-493 -134) translate(494 134) translate(0 12.158)"
                  ></path>
                  <path
                    d="M3.622 7.103l6.154 2.65c.297.129.638.118.927-.03L19.29 5.36c.64-.325.895-1.11.566-1.75-.305-.595-1.011-.86-1.632-.61l-4.247 1.707"
                    transform="translate(-493 -134) translate(494 134) translate(0 12.158)"
                  ></path>
                </g>
                <path
                  fill="#D6D6D6"
                  d="M10.228 0c3.013 0 5.455 2.447 5.455 5.466s-2.442 5.466-5.455 5.466-5.455-2.447-5.455-5.466S7.215 0 10.228 0"
                  transform="translate(-493 -134) translate(494 134)"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      ),
    },
    {
      id: "5",
      rentCategory: "Maintenance",
      cost: "10000",
      svg: (
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 7.07287V16C0 16.5522 0.447715 17 1 17H13.7693C14.3215 17 14.7693 16.5522 14.7693 16V7.08214C14.7693 6.7956 14.6463 6.52282 14.4317 6.33301L7.94259 0.594949C7.5599 0.256542 6.98366 0.260997 6.60624 0.605282L0.326069 6.33407C0.118355 6.52355 0 6.79172 0 7.07287Z"
            fill="#D6D6D6"
          ></path>
          <path
            d="M21.4247 17.8957L18.9567 15.5478C19.3423 14.687 19.4966 13.7478 19.4966 12.8087C19.4966 9.05219 16.4888 6 12.7868 6C11.63 6 10.4732 6.31302 9.54767 6.86085L11.9385 9.28697C12.247 9.20871 12.4784 9.20868 12.7868 9.20868C14.7921 9.20868 16.4117 10.8522 16.4117 12.887C16.4117 13.6696 16.1803 14.4522 15.7175 15.0783C15.6404 15.2348 15.1006 15.7826 14.9463 15.8609C14.3293 16.3304 13.6352 16.5652 12.7868 16.5652C10.7816 16.5652 9.16205 14.9217 9.16205 12.887C9.16205 12.5739 9.23918 12.3391 9.23918 12.0261L6.84835 9.59998C6.30849 10.6174 6 11.713 6 12.887C6 16.6435 9.00781 19.6957 12.7097 19.6957C13.7895 19.6957 14.8692 19.4609 15.7947 18.9913L19.9593 23.2957C20.8848 24.2348 22.273 24.2348 23.1985 23.2957C24.124 22.3565 24.124 20.9478 23.8155 20.3217L22.5815 19.0696"
            stroke="#999999"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
      ),
    },
    {
      id: "6",
      rentCategory: "Brokerage",
      cost: "6000",
      svg: (
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <ellipse
            cx="6.85682"
            cy="14"
            rx="3.91825"
            ry="4"
            fill="#D6D6D6"
          ></ellipse>
          <path
            d="M5.8774 7.63158C6.94279 3.81005 10.5238 1 14.7774 1C19.8709 1 24 5.02944 24 10C24 14.9706 19.8709 19 14.7774 19C13.0285 19 11.3933 18.525 9.99995 17.6999"
            stroke="#999999"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            d="M7.34671 18C3.28924 18 0 21.134 0 25H14.6934C14.6934 21.134 11.4042 18 7.34671 18Z"
            fill="#D6D6D6"
          ></path>
          <path
            d="M12.7343 8.48238H18.4507"
            stroke="#999999"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            d="M12.7343 6.06492H18.4507"
            stroke="#999999"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            d="M14.0831 6.05751C15.5106 6.05751 16.657 7.24758 16.657 8.72934C16.657 10.2111 15.5106 11.4012 14.0831 11.4012H12.7343L16.7358 15.1229"
            stroke="#999999"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
      ),
    },
    {
      id: "7",
      rentCategory: "Education Fees",
      cost: "9000",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="21"
          viewBox="0 0 21 21"
        >
          <g fill="#999" transform="translate(.5 .5)">
            <circle cx="6.318" cy="6.384" r="6.228" fillOpacity=".6"></circle>
            <path d="M13.651 4.492c.159-.154.4-.167.574-.044l.07.06 5.745 6.178c.058.062.096.14.112.222l.008.084v8.685c0 .222-.16.406-.371.443l-.082.007-4.737-.027c-.22-.001-.402-.16-.44-.37l-.007-.08v-.738c0-.249.201-.45.45-.45.22 0 .405.16.443.37l.007.08-.001.291 3.837.021v-8.056l-5.311-5.71-5.894 5.724v8.044l3.859-.004v-3.943c0-.22.16-.405.37-.443l.08-.007h2.61c.22 0 .405.16.443.37l.007.08v1.593c0 .249-.201.45-.45.45-.22 0-.405-.16-.443-.37l-.007-.08-.001-1.143h-1.71l.001 3.942c0 .22-.159.404-.369.443l-.08.007-4.758.006c-.222 0-.406-.159-.444-.369l-.007-.081v-8.685c0-.091.028-.18.078-.254l.058-.069 6.36-6.177z"></path>
          </g>
        </svg>
      ),
    },
  ],
  paymentOption: [
    { id: "1", option: "Debit/Credit", iconDetail: "bx:credit-card-front" },
    { id: "2", option: "Net Banking", iconDetail: "bxs:bank" },
  ],
  allBanks: [
    { id: "1", bankName: "HDFC Bank", icon: hdfc },
    { id: "2", bankName: "ICICI Bank", icon: icici },
    { id: "3", bankName: "CITI Bank", icon: citi },
    { id: "4", bankName: "AXIS Bank", icon: axis },
  ],
};
