import React from 'react';
import viewPending from '../../assets/img/view-pending-bg-rebranded.webp';

import RentCreditForm from './rent-credit-form/RentCreditForm';

function RentCredit() {
    return (
        <>
            <main id="rent-credit">
                <div className="main">
                    <div className="main-container">
                        <div className="main-wrapper">
                            <RentCreditForm />
                            <div className="view-pending-section">
                                <div className="view-pending-container-new">
                                    <img src={viewPending} alt="Abaris Payment background" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default RentCredit