export const PostpaidBillPaymentData = [
  {
    id: "1",
    topData: [
      {
        id: "1",
        title: "Postpaid Bill Payments Online",
        pargraph: [
          {
            id: "1",
            para: "In this fast-moving world, a fast internet connection is by far the most sought-after facility every individual craves for. Mobile postpaid bill payments make this hassle-free. So, what does this postpaid bill payment mean?",
          },
          {
            id: "2",
            para: "In simple words, ENJOY THE SERVICE FIRST, PAY FOR IT LATER. Postpaid bill payment service is somehow almost familiar to the credit card system. Here, postpaid bill payment includes mobile bills, name anything; d2h bills, cylinder bills, landline bills, and what not?",
          },
          {
            id: "3",
            para: "Recently, it has been seen that postpaid plans are giving better offers to customers compared to prepaid plans. Unlimited internet plans are a must these days. Also, you need not recharge your number multiple times as you do for a prepaid plan. So, postpaid bill payment makes our lives much more accessible than anything.",
          },
        ],
      },
      {
        id: 2,
        title: "HOW TO PAY A POSTPAID MOBILE BILLS?",
        pargraph: [
          {
            id: "1",
            para: "Any individual can recharge postpaid bills or pay postpaid mobile bills or can make any postpaid bill payment offline or online. Its always better to pay mobile bill online or, to be precise, pay postpaid mobile bill online. Offline recharge for gen Z is like going back to the black and white television era; however, online mobile bill payment is time and cost-saving",
          },
          {
            id: "2",
            para: "First, you need a bank account and a payment interface, VOILA! You are done. Different payment apps have additional steps but roughly speaking. You need to enter your mobile number, service operators name, bill amount, then pay using your dr or cr Card, UPI, internet banking, or wallet. YES, IT IS THAT EASY!",
          },
        ],
      },
      {
        id: "3",
        title: "BENEFITS OF USING Abaris Payment FOR POSTPAID BILL PAYMENT",
        pargraph: [
          {
            id: "1",
            para: "Abaris Payment is a wholly-owned subsidiary of Axis Bank Limited. It is Indias No.1 payments app. Users all over the country use Abaris Payment to make prepaid bill payments, postpaid bill payments, DTH, metro recharge, online mobile bill payments, and utility bill payments for a considerable number of service providers.",
          },
          {
            id: "2",
            para: "Abaris Payment offers you attractive offers and coupons for postpaid mobile recharge with the colossal amount of cashback, like icing on the cake. Not only online postpaid mobile recharge, but it also provides us with other postpaid bill payment facilities such as d2h, landline, rent, electricity, gas, postpaid mobile recharge, and broadband bill payment.",
          },
        ],
      },
      {
        id: "4",
        title: "IS Abaris Payment SECURE?",
        pargraph: [
          {
            id: 1,
            para: "Totally! All connections to Abaris Payment, whether browser or app, are 128-bit encrypted. This means that all the transactional & personal card details added here while making your online recharge will always be safe & secure.",
          },
        ],
      },
    ],
  },
  {
    id: "2",
    bottomDataHeader: "Frequently Asked Questions On Postpaid Bill Payments",
    data: [
      {
        id: "1",
        question: "How Long Does It Take To Process The Postpaid Bill Payment?",
        answer:
          "Generally, your postpaid mobile recharge is processed immediately. Shortly after your postpaid bill payment, Abaris Payment will confirm you through the mail, and your service provider will send you a confirmation text. If not, then contact your service operator. Usually, it takes less than 10 seconds for the transaction to complete, but if for any reason it is delayed, wait for 5 minutes after the payment is made.",
      },
      {
        id: "2",
        question: "What Operators Can We Make Bill Payment On Abaris Payment?",
        answer:
          "Abaris Payment facilitates online postpaid bill payments for many companies, including Airtel, Vi, Jio, MTNL, and BSNL.",
      },
      {
        id: "3",
        question: " How Can I Check My Postpaid Bill?",
        answer:
          "You can check postpaid bill payment by selecting your mobile operator, typing in your mobile number, and choosing postpaid in the Abaris Payment app; the interface will lead you to your online postpaid mobile bill details. In addition, you can check postpaid bill details by going to my account section, then “my transactions.”",
      },
      {
        id: "4",
        question:
          " Will There Be Any Charges For Delayed Postpaid Phone Bill Payments?",
        answer:
          "Yes, different operators charge differently for delayed postpaid mobile bill payments, which you can check on the internet.",
      },
    ],
  },
];
