import { useState } from "react";

export const PaymentForm = () => {
  const [obj, setObj] = useState({
    mobileNumber: "",
    operator: "",
  });

  const headingImge = "mobile_icon.svg";
  const paymentImg = "mobile.svg";

  const HandllerData = (e) => {
    const val = { ...obj };
    val[e.target.name] = e.target.value;
    setObj(val);
  };

  const CollectData = (e) => {
    e.preventDefault();
    console.log("hello", obj);
  };

  return (
    <div className="categorydetails-page">
      <div className="desktop-operators-main-header">
        <div className="desktop-heading-image">
          <img
            className="heading-image"
            src={require(`../../assets/img/${headingImge}`)}
          />
        </div>
        <div className="desktop-header-text">
          <div className="main-heading">Let's Get Your</div>
          <div className="sub-heading">Mobile Bill Payment Done!</div>
        </div>
        <img src={require(`../../assets/img/${paymentImg}`)} />
      </div>
      <form onSubmit={CollectData}>
        <div className="accountdetails-form">
          <div className="accountdetails-field-wrapper ">
            <div className="input__InputContainerWrapper-sc-77palv-0 gxoua input-wrapper accountdetails-field error">
              <div className="input__InputContainer-sc-77palv-1 kjqNrV input-container">
                <input
                  type="tel"
                  name="mobileNumber"
                  placeholder="Enter Mobile Number"
                  width="100%"
                  autoComplete="off"
                  className="input__InputWrapper-sc-77palv-4 iUhaYg"
                  value={obj.mobileNumber}
                  onChange={(e) => HandllerData(e)}
                />
                <p className="input__SubmitWrapper-sc-77palv-8 dMWSZh">
                  <img
                    src="https://pwa-cdn.Abaris Payment.in/pwa-static/pwa/images/dcc/clear_field.svg"
                    alt="Clear"
                    title="Clear"
                  />
                </p>
              </div>
            </div>
            <div className="field-description">
              Please Enter Valid Mobile Number
            </div>
          </div>
          <div className="accountdetails-field-wrapper last-field">
            <div className="input__InputContainerWrapper-sc-77palv-0 gxoua input-wrapper accountdetails-field test error">
              <div className="input__InputContainer-sc-77palv-1 kjqNrV input-container">
                <input
                  type="text"
                  name="operator"
                  placeholder="Operator"
                  width="100%"
                  autoComplete="off"
                  className="input__InputWrapper-sc-77palv-4 iUhaYg"
                  value={obj.operator}
                  onChange={(e) => HandllerData(e)}
                />
              </div>
            </div>
            <div className="field-description">
              Please Select Valid Operator
            </div>
          </div>
        </div>

        <div className="button-wrapper">
          <button className=" iKzHuO form-submit-btn" width="100%">
            NEXT
          </button>
        </div>
      </form>
    </div>
  );
};
