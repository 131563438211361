import icon1 from "./../../assets/img/circular.webp";
import icon2 from "./../../assets/img/circular-2.webp";
import icon3 from "./../../assets/img/circular-3.webp";
import icon4 from "./../../assets/img/DEPA00000NATES.webp";


export const ElectricityData = {
    allProviders: {
        id: 1,
        title: 'All Providers',
        data: [
            {
                id: 1,
                state: 'Andhra Pradesh',
                distributers: [
                    { id: 1, title: "Central Power Distribution Corporation Ltd. of Andhra Pradesh (APCPDCL)", icon: icon1 },
                    { id: 2, title: "Eastern Power Distribution Corporation Ltd. of Andhra Pradesh (APEPDCL)", icon: icon2 },
                    { id: 3, title: "Southern Power Distribution Corporation Ltd. of Andhra Pradesh (APSPDCL)", icon: icon3 },
                ]
            },
            {
                id: 2,
                state: 'Arunachal Pradesh',
                distributers: [
                    { id: 1, title: "Arunachal Pradesh Department of Power", icon: icon4 }
                ]
            },
            {
                id: 3,
                state: 'Assam',
                distributers: [
                    { id: 1, title: "ANI Network Pvt Ltd", icon: icon2 }
                ]
            },
            {
                id: 4,
                state: 'Bihar',
                distributers: [
                    { id: 1, title: "North Bihar Power (NBPDCL)", icon: icon1 },
                    { id: 2, title: "South Bihar Power (SBPDCL)", icon: icon3 }
                ]
            },
            {
                id: 5,
                state: 'Chhattisgarh',
                distributers: [
                    { id: 1, title: "North Bihar Power (NBPDCL)", icon: icon1 },

                ]
            },
            {
                id: 6,
                state: 'Chandigarh',
                distributers: [
                    { id: 1, title: "North Bihar Power (NBPDCL)", icon: icon2 },

                ]
            },
            {
                id: 7,
                state: 'Daman And Diu',
                distributers: [
                    { id: 1, title: "North Bihar Power (NBPDCL)", icon: icon3 },

                ]
            },
            {
                id: 8,
                state: 'Delhi',
                distributers: [
                    { id: 1, title: "North Bihar Power (NBPDCL)", icon: icon1 },
                    { id: 2, title: "North Bihar Power (NBPDCL)", icon: icon3 },
                    { id: 3, title: "North Bihar Power (NBPDCL)", icon: icon2 },
                    { id: 4, title: "North Bihar Power (NBPDCL)", icon: icon3 },

                ]
            },
            {
                id: 9,
                state: 'Dadra And Nagar Haveli',
                distributers: [
                    { id: 1, title: "North Bihar Power (NBPDCL)", icon: icon2 },

                ]
            },
            {
                id: 10,
                state: 'Goa',
                distributers: [
                    { id: 1, title: "North Bihar Power (NBPDCL)", icon: icon1 },

                ]
            },
            {
                id: 11,
                state: 'Gujarat',
                distributers: [
                    { id: 1, title: "North Bihar Power (NBPDCL)", icon: icon3 },
                    { id: 2, title: "North Bihar Power (NBPDCL)", icon: icon2 },
                    { id: 3, title: "North Bihar Power (NBPDCL)", icon: icon1 },
                    { id: 4, title: "North Bihar Power (NBPDCL)", icon: icon2 },
                    { id: 5, title: "North Bihar Power (NBPDCL)", icon: icon3 },
                    { id: 6, title: "North Bihar Power (NBPDCL)", icon: icon2 },

                ]
            },
            {
                id: 12,
                state: 'Himachal Pradesh',
                distributers: [
                    { id: 1, title: "North Bihar Power (NBPDCL)", icon: icon3 },
                ]
            },
            {
                id: 13,
                state: 'Haryana',
                distributers: [
                    { id: 1, title: "North Bihar Power (NBPDCL)", icon: icon2 },
                ]
            },
            {
                id: 14,
                state: 'Jammu And Kashmir',
                distributers: [
                    { id: 1, title: "North Bihar Power (NBPDCL)", icon: icon1 },
                ]
            },
            {
                id: 15,
                state: 'Jharkhand',
                distributers: [
                    { id: 1, title: "North Bihar Power (NBPDCL)", icon: icon2 },
                    { id: 2, title: "North Bihar Power (NBPDCL)", icon: icon3 },
                ]
            },
            {
                id: 16,
                state: 'Kerala',
                distributers: [
                    { id: 1, title: "North Bihar Power (NBPDCL)", icon: icon2 },
                    { id: 2, title: "North Bihar Power (NBPDCL)", icon: icon3 },
                    { id: 3, title: "North Bihar Power (NBPDCL)", icon: icon3 },
                ]
            },
            {
                id: 17,
                state: 'Karnataka',
                distributers: [
                    { id: 1, title: "North Bihar Power (NBPDCL)", icon: icon2 },
                    { id: 2, title: "North Bihar Power (NBPDCL)", icon: icon3 },
                    { id: 3, title: "North Bihar Power (NBPDCL)", icon: icon3 },
                    { id: 4, title: "North Bihar Power (NBPDCL)", icon: icon2 },
                    { id: 5, title: "North Bihar Power (NBPDCL)", icon: icon1 },
                    { id: 6, title: "North Bihar Power (NBPDCL)", icon: icon2 },
                ]
            },
            {
                id: 18,
                state: 'Lakshadweep',
                distributers: [
                    { id: 1, title: "Lakshadweep Electricity Department", icon: icon2 },
                ]
            },
            {
                id: 19,
                state: 'Meghalaya',
                distributers: [
                    { id: 1, title: "Meghalaya Power", icon: icon1 },
                ]
            },
            {
                id: 20,
                state: 'Maharashtra',
                distributers: [
                    { id: 1, title: "Lakshadweep Electricity Department", icon: icon2 },
                    { id: 2, title: "Lakshadweep Electricity Department", icon: icon2 },
                    { id: 3, title: "Lakshadweep Electricity Department", icon: icon2 },
                    { id: 4, title: "Lakshadweep Electricity Department", icon: icon2 },
                ]
            },
            {
                id: 21,
                state: 'Madhya Pradesh',
                distributers: [
                    { id: 1, title: "Lakshadweep Electricity Department", icon: icon2 },
                    { id: 2, title: "Lakshadweep Electricity Department", icon: icon2 },
                    { id: 3, title: "Lakshadweep Electricity Department", icon: icon2 },
                    { id: 4, title: "Lakshadweep Electricity Department", icon: icon2 },
                ]
            },
            {
                id: 22,
                state: 'Mizoram',
                distributers: [
                    { id: 1, title: "Meghalaya Power", icon: icon1 },
                ]
            },
            {
                id: 23,
                state: 'Nagaland',
                distributers: [
                    { id: 1, title: "Meghalaya Power", icon: icon2 },
                ]
            },
            {
                id: 24,
                state: 'Odisha',
                distributers: [
                    { id: 1, title: "Lakshadweep Electricity Department", icon: icon2 },
                    { id: 2, title: "Lakshadweep Electricity Department", icon: icon2 },
                    { id: 3, title: "Lakshadweep Electricity Department", icon: icon2 },
                    { id: 4, title: "Lakshadweep Electricity Department", icon: icon2 },
                    { id: 5, title: "Lakshadweep Electricity Department", icon: icon2 },
                ]
            },
            {
                id: 25,
                state: 'Punjab',
                distributers: [
                    { id: 1, title: "Meghalaya Power", icon: icon2 },
                ]
            },
            {
                id: 26,
                state: 'Puducherry',
                distributers: [
                    { id: 1, title: "Meghalaya Power", icon: icon2 },
                ]
            },
            {
                id: 27,
                state: 'Rajasthan',
                distributers: [
                    { id: 1, title: "Lakshadweep Electricity Department", icon: icon2 },
                    { id: 2, title: "Lakshadweep Electricity Department", icon: icon3 },
                    { id: 3, title: "Lakshadweep Electricity Department", icon: icon1 },
                    { id: 4, title: "Lakshadweep Electricity Department", icon: icon1 },
                    { id: 5, title: "Lakshadweep Electricity Department", icon: icon3 },
                    { id: 6, title: "Lakshadweep Electricity Department", icon: icon1 },
                    { id: 7, title: "Lakshadweep Electricity Department", icon: icon2 },
                ]
            },
            {
                id: 28,
                state: 'Sikkim',
                distributers: [
                    { id: 1, title: "Meghalaya Power", icon: icon2 },
                    { id: 2, title: "Meghalaya Power", icon: icon2 },
                ]
            },
            {
                id: 29,
                state: 'Tamil Nadu',
                distributers: [
                    { id: 1, title: "Meghalaya Power", icon: icon2 },
                ]
            },
            {
                id: 30,
                state: 'Tripura',
                distributers: [
                    { id: 1, title: "Meghalaya Power", icon: icon3 },
                ]
            },
            {
                id: 31,
                state: 'Uttarakhand',
                distributers: [
                    { id: 1, title: "Meghalaya Power", icon: icon1 },
                ]
            },
            {
                id: 32,
                state: 'Uttar Pradesh',
                distributers: [
                    { id: 1, title: "Meghalaya Power", icon: icon1 },
                    { id: 2, title: "Meghalaya Power", icon: icon1 },
                    { id: 3, title: "Meghalaya Power", icon: icon1 },
                    { id: 4, title: "Meghalaya Power", icon: icon1 },
                    { id: 5, title: "Meghalaya Power", icon: icon1 },
                ]
            },
            {
                id: 33,
                state: 'West Bengal',
                distributers: [
                    { id: 1, title: "Meghalaya Power", icon: icon3 },
                    { id: 2, title: "Meghalaya Power", icon: icon2 },
                ]
            },
            {
                id: 34,
                state: 'Pan India',
                distributers: [
                    { id: 1, title: "Meghalaya Power", icon: icon2 },
                ]
            },

        ]
    },

    aboutData: [
        {
            id: 1,
            heading: "MAKE INSTANT ELECTRICITY BILL PAYMENT VIA Abaris Payment!",
            paragraphs: [
                { para: "Your electricity bill payment gets easier with Abaris Payment!. Trusted by over 27 million users, Abaris Payment is your one-stop shop for online recharges and bill payments! There are many online bill payment facilities provided by Abaris Payment. Some of these include, scheduling payments on time, save the customer information for transaction in the future, and other different paying alternatives.", },
                { para: "Online payment eliminates any kind of hassles and ensures that bill payment is extremely easy. This is where Abaris Payment comes in and greatly reduces your difficulties. We facilitate online electricity bill payment for major service providers including Reliance Energy, NDPL, BESCOM, Jodhpur Vidyut Nigam Limited and others." }
            ]
        },
        {
            id: 2,
            heading: "WHY PAY ELECTRICITY BILLS WITH Abaris Payment?",
            paragraphs: [
                { para: "Your electricity bill payment gets easier with Abaris Payment!. Trusted by over 27 million users, Abaris Payment is your one-stop shop for online recharges and bill payments! There are many online bill payment facilities provided by Abaris Payment. Some of these include, scheduling payments on time, save the customer information for transaction in the future, and other different paying alternatives.", },
                { para: "Online payment eliminates any kind of hassles and ensures that bill payment is extremely easy. This is where Abaris Payment comes in and greatly reduces your difficulties. We facilitate online electricity bill payment for major service providers including Reliance Energy, NDPL, BESCOM, Jodhpur Vidyut Nigam Limited and others." }
            ]
        },


    ]
}