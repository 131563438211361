import { useState } from "react";
import OwlCarousel from 'react-owl-carousel';  
import PaymentsType from "./paymentsType/PaymentsType";
import SlideItem from "./slideItem/SlideItem";

import {HomeData} from './../../pages/home/MockData'

const sliderOption = {
    options: {
        loop: true,
        dots: false,
        nav: false,
        margin: 10,
        responsiveClass: true,

        responsive: {
            0: {
            items: 1,
            nav: true,
            },
            600: {
            items: 3,
            nav: false,
            },
            1000: {
            items: 2.8,
            nav: true,
            loop: true,
            autoplay:true,
            slideTransition: 'linear',
            autoplaySpeed: 2000,
            autoplayTimeout: 3000,
            },
        },
    }
}
function HomeTab() {
    const [isActive,setIsActive] = useState(false)
    const {paymentOptions, sliderData} = HomeData;
    const expandList = ()=>{
    setIsActive(!isActive)
    }
  return (
    <>
    {/* main--container  */}
    <div className="main-content color-banner">
                  <div className="home-tab-wrapper">
                      <div className="grid-section">
                          <h3 className="section-heading">Payments</h3>
                          <ul className={`section-tiles-wrapper ` + (isActive ? ' height': '')} >
                                <li className= {`more-icon ` + (isActive ? ' setOrder': '')} style={{ order: "12" }} onClick={expandList}>
                                <div className="tile-img-tag expand">
                                    <span></span>
                                </div>
                                <div className="tile-type more-cn">More</div>
                                <div className="tile-type less-cn">Less</div>
                                </li>
                                {paymentOptions && paymentOptions.map((list,i)=>{
                                return  <PaymentsType key={list.id} list = {list}/>
                                })}
                            </ul>
                      </div>
                      {/* <!-- ====home-slider-section --> */}
                      <div className="home-slider-section investments-slider-section">
                          <h3 className="section-heading">Investments &amp; Loans</h3>
                          <div className="slider-wrapper home-slider  slider-arrows">
                              <div className="slider-track">
                                  <div className="slider-section">
                                      <div className="slider">
                                          <OwlCarousel className="slider-one owl-carousel owl-theme" {...sliderOption.options}  >
                                              {sliderData && sliderData.map((item,i)=>{
                                                return <SlideItem key={item.id} slideItem = {item}/>
                                              })}
                                              
                                              
                                              
                                          </OwlCarousel>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
    </>
  );
}
export default HomeTab;
