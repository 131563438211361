export const AboutSection = ({data}) => {
    console.log(data)
    return (
      <>
            {data.footerData.map ( (item,id) => {
                return <div key="id" className="content-section" style={{maxHeight : '100%'}}>
                    <h1>{item.heading}</h1>
                    <p>{item.para1}</p>
                    <p>{item.para2}</p>
                    <p>{item.para3}</p>
                    <p>{item.para4}</p>
                    <p>{item.para5}</p>
                </div>
            })}
      </>
     
    )
}