import { lazy, Suspense, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";

import { TopNavbar } from "./components/navbar/TopNavbar";
import PostpaidBillPayment from "./pages/postpaidBillPayment";
import { PaymentOption } from "./components/rentPayment/PaymentOption";
import RentpaymentPage from "./pages/rentPayment";
import RentCreditPage from "./pages/rentCreditPage";
import BroadBand from "./pages/broadBand";
import Electricity from "./pages/electricity";
import { Water } from "./pages/water";
import OttSubscriptionsPage from "./pages/subscriptions";
import CableTvRechargePage from "./pages/cableTvRecharge";
import TaxPayment from "./pages/taxPayment";
import Fastag from "./pages/fastag";
import GasPaymentPage from "./pages/gasPayment";
import FooterPage from "./pages/footer/index";
import HomePage from "./pages/home";
import TermsAndConditions from "./pages/terms&conditions";
import RefundPolicy from "./pages/refundPolicy.js";
import ContactUspage from "./pages/contactUs";
import PrivacyPage from "./pages/privacyPolicy";

const Login = lazy(() => import("./components/login/Login"));
function App() {
  const [show, setShow] = useState(false);

  const openModal = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <div className="App">
      <TopNavbar openModal={openModal} />

      {show && (
        <Suspense fallback={<></>}>
          <Login handleClose={handleClose} show={true} />
        </Suspense>
      )}
      <Routes>
        <Route path="/" element={<HomePage />}></Route>
        <Route
          path={"/postpaid-bill-payment"}
          element={<PostpaidBillPayment />}
        ></Route>
        <Route
          path="/cable-tv-recharge"
          element={<CableTvRechargePage />}
        ></Route>
        <Route path={"/rent-payment"} element={<RentpaymentPage />} />
        <Route
          path={"/rent-payment/payment-option"}
          element={<PaymentOption />}
        />
        <Route
          path="/ott-subscription"
          element={<OttSubscriptionsPage />}
        ></Route>
        <Route path={"/rent-credit"} element={<RentCreditPage />}></Route>
        <Route path={"/gas-bill-payment"} element={<GasPaymentPage />}></Route>
        <Route path="/broadband" element={<BroadBand />}></Route>
        <Route path="/electricity" element={<Electricity />}></Route>
        <Route path="/water-bill-payment" element={<Water />}></Route>
        <Route path="/tax-payment" element={<TaxPayment />} />
        <Route path="/fastag-recharge" element={<Fastag />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/contact-us" element={<ContactUspage />} />
        <Route path="/privacy-policy" element={<PrivacyPage />} />
      </Routes>
      <FooterPage />
    </div>
  );
}
export default App;
