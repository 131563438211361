export const OperatorTile = ({ data }) => {
  return (
    <div className="operator-tile  flex-center" tabIndex="0">
      <div className="logo-container">
        <img src={data.icon} alt="Operator Logo" className="operator-img" />
      </div>
      <div className="operator-content">
        <div className="operator-name">{data.title}</div>
      </div>
    </div>
  );
};
