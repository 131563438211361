import { AboutSection } from "../../components/postpaidBillPayment/AboutSection"
import { PaymentForm } from "../../components/postpaidBillPayment/PaymentForm";


const PostpaidBillPayment = () => {
    const backgroundImg = "bg-rent.svg"
    return (
        <div>
            <div className="main-container " style={{ background: `url(${backgroundImg})` }}>
                <div className="main-wrapper">
                    <div className="main-content  w-80">
                        <PaymentForm/>
                        <AboutSection/>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PostpaidBillPayment