import React from 'react'
import RentCredit from '../../components/rent-credit/RentCredit'

function RentCreditPage() {
  return (
    <>
      <RentCredit />
    </>
  )
}

export default RentCreditPage