import React from 'react'
import PrivacyPolicy from '../../components/PrivacyPolicy/PrivacyPolicy'

function PrivacyPage() {
    return (
        <>
            <PrivacyPolicy />
        </>
    )
}

export default PrivacyPage
