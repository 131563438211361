import icon1 from "./../../assets/img/Paytm_Payments_Bank_FASTag.webp";
import icon2 from "./../../assets/img/AIRT00000NATVI.webp";
import icon3 from "./../../assets/img/Axis_Bank_FASTag.webp";
import icon4 from "./../../assets/img/Bank_of_Baroda_-_Fastag.webp";
import icon5 from "./../../assets/img/Equitas_FASTag_Recharge.webp";
import icon6 from "./../../assets/img/HDFC_-_FASTag.webp";
import icon7 from "./../../assets/img/ICICI_Bank_Fastag.webp";
import icon8 from "./../../assets/img/IDBI00000NATK7.webp";
import icon9 from "./../../assets/img/IDFC_FIRST_Bank.webp";
import icon10 from "./../../assets/img/IOBF00000NATV1.webp";
import icon11 from "./../../assets/img/Indian_Highways_Management_Company_Ltd_FASTag.webp";
import icon12 from "./../../assets/img/IndusInd_Bank_FASTag.webp";
import icon13 from "./../../assets/img/JAMM00000NATWB.webp";
import icon14 from "./../../assets/img/KARN00000NATWS.webp";
import icon15 from "./../../assets/img/Kotak_Mahindra_Bank_-_Fastag.webp";
import icon16 from "./../../assets/img/Paul_Merchants.webp";
import icon17 from "./../../assets/img/2914000SBIFASTAG.webp";
import icon18 from "./../../assets/img/The_Federal_Bank_Limited.webp";
import icon19 from "./../../assets/img/Transaction_Analyst_(T_Wallet,_TA_Wallet_and_TAG-ITI_Wallet).webp";
import icon20 from "./../../assets/img/TRAN00000NATEI.webp";
import icon21 from "./../../assets/img/UCOB00000NATEH.webp";
import icon22 from "./../../assets/img/SOUT00000NAT99.webp";
import icon23 from "./../../assets/img/SARA00000NAT1L.webp";
import icon24 from "./../../assets/img/ft_fallback.webp";

const fastagMockData = {
  allProvider: [
    { id: "1", title: "Paytm Payments Bank FASTag", icon: icon1 },
    { id: "2", title: "Airtel Payments Bank FASTag", icon: icon2 },
    { id: "3", title: "Axis Bank FASTag", icon: icon3 },
    { id: "4", title: "Bank of Baroda - Fastag", icon: icon4 },
    { id: "5", title: "Equitas FASTag Recharge", icon: icon5 },
    { id: "6", title: "HDFC - FASTag", icon: icon6 },
    { id: "7", title: "ICICI Bank Fastag", icon: icon7 },
    { id: "8", title: "IDBI Bank Fastag", icon: icon8 },
    { id: "9", title: "IDFC FIRST Bank - FasTag", icon: icon9 },
    { id: "10", title: "IOB Fastag", icon: icon10 },
    {
      id: "11",
      title: "Indian Highways Management Company Ltd FASTag",
      icon: icon11,
    },
    { id: "12", title: "IndusInd Bank FASTag", icon: icon12 },
    { id: "13", title: "Jammu and Kashmir Bank FASTag", icon: icon13 },
    { id: "14", title: "Karnataka Bank Fastag", icon: icon14 },
    { id: "15", title: "Kotak Mahindra Bank - Fastag", icon: icon15 },
    { id: "16", title: "Paul Merchants", icon: icon16 },
    { id: "17", title: "State Bank of India FASTag", icon: icon17 },
    { id: "18", title: "The Federal Bank Limited", icon: icon18 },
    { id: "19", title: "Transaction Analyst", icon: icon19 },
    { id: "20", title: "Transcorp International Limited", icon: icon20 },
    { id: "21", title: "UCO Bank Fastag", icon: icon21 },
    { id: "22", title: "South Indian Bank Fastag", icon: icon22 },
    { id: "23", title: "Saraswat Bank - FASTag", icon: icon23 },
    { id: "24", title: "Indian Bank Fastag Recharge", icon: icon24 },
  ],
  content: [
    // mainHeading:'',
    {
      id: "1",
      fistHeading: "Online FASTag Recharge Made Simple With Abaris Payment",
      paragraphs: [
        {
          id: "1",
          paragraph: `To avoid congestion on toll booths, reduce waiting time, and eliminate cash payment inconveniences, the Ministry of Road Transport and Highway of India brought in a digital payment system called ‘FASTag’.`,
        },
        {
          id: "2",
          paragraph:
            "FASTag is a rechargeable tag used for the collection of tolls allowing automatic payment deductions. Generally, they are affixed on the widescreen of the vehicle. With FASTag recharge, you do not have to stop your car at toll plazas to make a payment. Once you cross the toll plaza, the required amount will get deducted automatically from your prepaid wallet or bank account. This option has no expiry date, and you can use it as long as it is not tampered with and readable.",
        },
        {
          id: "3",
          paragraph:
            "Keeping in mind the need of customers, Abaris Payment has introduced FASTag online recharge service, making interstate travel smoother and more convenient. To help you travel worry-free, Abaris Payment lets you recharge your FASTag online in just a few minutes.",
        },
      ],
    },
    {
      id: "2",
      heading: "How To Get FASTAg Online?",
      paragraphs: [
        {
          id: "1",
          paragraph:
            "In order to eliminate congestion on toll booths, avoid cash payment issues, reduce waiting times, a digital payment option FASTag was introduced. It was launched, keeping the needs of customers in consideration. With Abaris Payment App you can recharge FASTag online, and with that, interstate travel can be convenient and smoother. If your question is how to get FASTag online, submit your KYC documents and other necessary details to create an account. With that, you can make payments when crossing a toll, and there is no need to wait at the toll plaza.",
        },
      ],
    },
    {
      id: "3",
      heading: "List Of FASTag Providers Supported On Abaris Payment",
      paragraph:
        "Before using FASTag recharge online, you must learn about the top providers supported by Abaris Payment. With that, you will have an idea of the brands they support. They are:",
      item: [
        { id: "1", listParagraphA: "1. Paytm Payments Bank FASTag" },
        { id: "2", listParagraphA: "2. HDFC Bank FASTag" },
        { id: "3", listParagraphA: "3. Axis Bank FASTag" },
        { id: "4", listParagraphA: "4. Bank of Baroda FASTag" },
        { id: "5", listParagraphA: "5. Equitas FASTag Recharge" },
        { id: "6", listParagraphA: "6. ICICI Bank FASTag" },
        { id: "7", listParagraph: "7. IDFC First Bank FASTag" },
        { id: "8", listParagraphA: "8. IndusInd Bank FASTag" },
        { id: "9", listParagraph: "9. Paul Merchants" },
        { id: "10", listParagraph: "10. Kotak Mahindra Bank FASTag" },
        {
          id: "11",
          listParagraph: "12. Indian Highways Management Company FASTag",
        },
      ],
    },
    {
      id: "4",
      heading: "How To Make Fastag Recharge Online At Abaris Payment?",
      paragraph:
        "Do you wish to learn how to make online FASTag recharge with mobile? Here are the steps that wi ll help you transact payment safely.",
      item: [
        {
          id: "1",
          list: "First, you must download the Abaris Payment application on your mobile and click on FASTag Recharge under the New Payments section.",
        },
        {
          id: "2",
          list: "Click on the issuing bank of FASTag and provide the registration number on the vehicle.",
        },
        { id: "3", list: "Enter that value that you have to pay." },
        {
          id: "4",
          list: "Pick any preferred payment options like debit card, credit card, net banking, Abaris Payment balance, etc., to complete the payment process.",
        },
      ],
    },
    {
      id: "5",
      heading: "What Are The Documents Needed For FASTag Registration?",
      paragraph:
        "While applying for a recharge FASTag, you must submit a few documents, and they are",
      item: [
        { id: "1", listParagraph: "1. Vehicle Registration Certificate" },
        { id: "2", listParagraph: "2. KYC Documents" },
        { id: "3", listParagraph: "3. A passport size photo" },
      ],
    },
    {
      id: "6",
      heading: "Benefits Of FASTag",
      itemBenefits: [
        {
          id: "1",
          head: "Saves Time",
          listParagraph:
            "Using a FASTag, you do not have to wait in a long queue to make the payment.",
        },
        {
          id: "2",
          head: "Online Recharge",
          listParagraph:
            "Being able to recharge online allows for a hassle-free process, and you can complete this process from anywhere. But for that, you need to have a strong internet connection.",
        },
        {
          id: "3",
          head: "Saves Fuel",
          listParagraph:
            "It helps burn precious fuel, which you might have burned while waiting in the queue.",
        },
        {
          id: "4",
          head: "Saves Money",
          listParagraph:
            "By saving fuel consumption, you are reducing your expenses as well.",
        },
        {
          id: "5",
          head: "Smooth Toll Passing",
          listParagraph:
            "With FASTag, you can have smooth toll passing as the amount will directly get deducted from your wallet.",
        },
        {
          id: "6",
          head: "Transaction Alerts On Mobile",
          listParagraph:
            "While approaching a toll or making a transaction, you will receive a transaction alert on your smartphone. It makes the entire process much easier while traveling from one state to another.",
          listParagraph2:
            "Now you can deposit money using this option at the toll plaza and reduce the waiting time.",
        },
      ],
    },
    {
      id: "7",
      heading: "Frequently Asked Questions On Fastag Recharge Online",
      item: [
        {
          id: "1",
          head: "1. What Is Fastag Recharge?",
          listParagraph:
            "FASTag is electronic toll-collection chip operated by National Highway Authority of India. Fastag Sticker is affixed on the windscreen of the vehicle. This means that you do not have to wait at toll gates as charges are deducted electronically.",
        },
        {
          id: "2",
          head: "2. What Are The Benefits Of Using FASTag?",
          listParagraph:
            "As per the government’s offering, toll payments made via FASTag are eligible for 2.5 percent cashback. In addition, FASTags ensure smoother traffic movement and prevent congestion at toll booths.",
        },
        {
          id: "3",
          head: "3. Is FASTag Mandatory?",
          listParagraph:
            "Yes. FASTag has been made mandatory by the government of India for all private and commercial vehicles.",
        },
        {
          id: "4",
          head: "4. How To Buy A FASTag?",
          listParagraph:
            "The NHAI has authorised 23 banks to provide FASTag cards. These banks, in association with NHAI plazas, petrol pumps, and transport hubs have set up more than 28000 FASTag sale terminals across the country. Apart from this, several digital platforms such as Flipkart and Amazon provide these cards online.",
        },
      ],
    },
  ],
};
export default fastagMockData;
