export const mockData = {
    title: "General",
    data: [
        { id: "1", para: "We at Rcube Planet Private Limited (https://www.rechargecube.in/) are committed to the protection of Information provided by our users to us. You agree to use our services and provides your consent to collect your information in accordance with the terms of this Privacy Policy (“Privacy Policy”)." },
        { id: "2", para: "We at RechargeCube (https://www.rechargecube.in/) are committed to the protection of Information provided by our users to us. You agree to use our services and provides your consent to collect your information in accordance with the terms of this Privacy Policy (“Privacy Policy”).Information Gathered." },
        {
            id: "3", para: "Traffic Data Entries While user visit www.rechargecube.in automatically following.",
            one: "Entries are been tracked:",
            two: "User IP addresses;",
            three: "User Domain servers;",
            four: "Types of computers by which user is accessing the Website;",
            five: "Types of web browsers used to access the RechargeCube Website;",
            six: "Our Affiliate partner source which may have sent you our Website;;"
        },
        { id: "4", para: "Information Gathered from UsersAs our users would be accessing certain pages of the Website, providing access to these areas would require our users to provide with certain Personal Information which includes:", one: "Users First and Last name, e-mail address & mobile number.", two: "Mobile No. or other details, when we receive a direct Calls from our users looking for support;", three: "Any Information that is been collected via cookies, that generate while you serve different areas of website." },
        { id: "5", paraTit: "Legal Obligations", para: "To prevent any fraudulent activity and money laundering, we require certain information as per under Legal Obligations. This information might include Third Party Information, any related financial history, at times when we feel it is necessary t or important to minimise our financial risks and prevent any frauds.", one: "How Information is used and stored:" },
    ]
}