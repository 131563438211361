import img1 from "../../assets/img/PORT00000ANIWE.webp";
import img2 from "../../assets/img/default_fallback.webp";
import img3 from "../../assets/img/GOADMA000GOA06.webp";
import img4 from "../../assets/img/GOADMA000GOA06.webp";
import img5 from "../../assets/img/GOADMA000GOA06.webp";
import img6 from "../../assets/img/GOADMA000GOA06.webp";
import img7 from "../../assets/img/GOADMA000GOA06.webp";
import img8 from "../../assets/img/GOADMA000GOA06.webp";
import img9 from "../../assets/img/GOADMA000GOA06.webp";
import img10 from "../../assets/img/GOADMA000GOA01.webp";
import img11 from "../../assets/img/GOADMA000GOA06.webp";
import img12 from "../../assets/img/GOADMA000GOA06.webp";
import img13 from "../../assets/img/GOADMA000GOA06.webp";
import img14 from "../../assets/img/GOADMA000GOA06.webp";
import img15 from "../../assets/img/GOADMA000GOA06.webp";
import img16 from "../../assets/img/GOADMA000GOA06.webp";
import img17 from "../../assets/img/AMC000000GUJ01.webp";
import img18 from "../../assets/img/RAJK00000RJKT2.webp";
import img19 from "../../assets/img/MUNI00000SHMXA.webp";
import img20 from "../../assets/img/MUNI00000RHT61.webp";
import img21 from "../../assets/img/default_fallback.webp";
import img22 from "../../assets/img/GULB00000KARMQ.webp";
import img23 from "../../assets/img/HUBL00000KAR9T.webp";
import img24 from "../../assets/img/DIRE00000KARZ0.webp";
import img25 from "../../assets/img/SHIV00000KARJV.webp";
import img26 from "../../assets/img/JEJU00000PUNRU.webp";
import img27 from "../../assets/img/KALY00000THAO1.webp";
import img28 from "../../assets/img/default_fallback.webp";
import img29 from "../../assets/img/MCGM00000MAHYV.webp";
import img30 from "../../assets/img/MIRA00000MAHI5.webp";
import img31 from "../../assets/img/PMCPT.webp";
import img32 from "../../assets/img/TALE00000MAHW6.webp";
import img33 from "../../assets/img/THAN00000THAUL.webp";
import img34 from "../../assets/img/VASA00000THAUC.webp";
import img35 from "../../assets/img/MADH00000MAPSS.webp";
import img36 from "../../assets/img/MUNI00000MAP94.webp";
import img37 from "../../assets/img/PUDU00000PUD1U.webp";
import img38 from "../../assets/img/AJME00000AJMY9.webp";
import img39 from "../../assets/img/GREA00000CHEHP.webp";
import img40 from "../../assets/img/UDDU00000UTTUP.webp";
import img41 from "../../assets/img/NAGA00000AGRQC.webp";
import img42 from "../../assets/img/NAGA00000ALG1B.webp";
import img43 from "../../assets/img/ABLP03000LLR5E.webp";
import img44 from "../../assets/img/NAGA00000MATYB.webp";
import img45 from "../../assets/img/MINI00000MEE5D.webp";
import img46 from "../../assets/img/ABLP02000KHRGG.webp";
import img47 from "../../assets/img/PRAY00000ALLXR.webp";
import img48 from "../../assets/img/default_fallback.webp";
import img49 from "../../assets/img/default_fallback.webp";

export const mockData = {
  allprovider: [
    {
      id: "1",
      state: "Andaman And Nicobar Islands",
      offices: [
        {
          id: "1",
          url: img1,
          title: "Port Blair Municipal Council",
        },
      ],
    },
    {
      id: "2",
      state: "Chhattisgarh",
      offices: [{ id: "1", url: img2, title: "Raipur Municipal Corporation" }],
    },
    {
      id: "3",
      state: "Goa",
      offices: [
        { id: "1", url: img3, title: "Bicholim Municipal council" },
        { id: "2", url: img4, title: "Canacona Municipal council" },
        { id: "3", url: img5, title: "Cuncolim Municipal council" },
        { id: "4", url: img6, title: "Curchorem Cacora Municipal council" },
        { id: "5", url: img7, title: "Margao Municipal Council  " },
        { id: "6", url: img8, title: "Mhapsa Municipal Council" },
        { id: "7", url: img9, title: "Mormugao Municipal Council" },
        { id: "8", url: img10, title: "Panaji City Corporation" },
        { id: "9", url: img11, title: "Pernem Municipal council" },
        { id: "10", url: img12, title: "Ponda Municipal Council" },
        { id: "11", url: img13, title: "Quepem Municipal council" },
        { id: "12", url: img14, title: "Sanguem Municipal council" },
        { id: "13", url: img15, title: "Sankhali Municipal council" },
        { id: "14", url: img16, title: "Valpoi Municipal council" },
      ],
    },
    {
      id: "4",
      state: "Gujarat",
      offices: [
        { id: "1", url: img17, title: "Ahmedabad Municipal Corporation" },
        { id: "2", url: img18, title: "Rajkot Municipal Corporation" },
      ],
    },
    {
      id: "5",
      state: "Himachal Pradesh",
      offices: [{ id: "1", url: img19, title: "Shimla Municipal Corporation" }],
    },
    {
      id: "6",
      state: "Haryana",
      offices: [{ id: "1", url: img20, title: "Rohtak Municipal Corporation" }],
    },
    {
      id: "7",
      state: "Jharkhand",
      offices: [{ id: "1", url: img21, title: "SUDA Jharkhand" }],
    },
    {
      id: "8",
      state: "Karnataka",
      offices: [
        { id: "1", url: img22, title: "Gulbarga City Corporation" },
        { id: "2", url: img23, title: "Hubli-Dharwad Municipal Corporation" },
        {
          id: "3",
          url: img24,
          title: "Karnataka Directorate of Municipal Administration",
        },
        { id: "3", url: img25, title: "Shivamogga City Corporation" },
      ],
    },
    {
      id: "9",
      state: "Maharashtra",
      offices: [
        { id: "1", url: img26, title: "Jejuri Nagarparishad" },
        {
          id: "2",
          url: img27,
          title: "Kalyan Dombivali Municipal Corporation",
        },
        {
          id: "3",
          url: img28,
          title: "Kolhapur Municipal Corporation - Property Tax",
        },
        { id: "4", url: img29, title: "MCGM Property Tax" },
        { id: "5", url: img30, title: "Mira Bhayander Municipal Corporation" },
        {
          id: "6",
          url: img31,
          title: "Pune Municipal Corporation (PMC) Property Tax",
        },
        {
          id: "7",
          url: img32,
          title:
            "Talegaon Dabhade Nagar Parishad - Municipal Taxes and Services Payments",
        },
        {
          id: "8",
          url: img33,
          title: "Thane Municipal Corporation Property Tax",
        },
        {
          id: "9",
          url: img34,
          title: "Vasai Virar Municipal Corporation - Property",
        },
      ],
    },
    {
      id: "10",
      state: "Madhya Pradesh",
      offices: [
        {
          id: "1",
          url: img35,
          title: "Madhya Pradesh Urban (e-Nagarpalika) - Property Tax",
        },
        { id: "2", url: img36, title: "Municipal Corporation Bhopal" },
      ],
    },
    {
      id: "11",
      state: "Puducherry",
      offices: [{ id: "1", url: img37, title: "Puducherry - Property Tax" }],
    },
    {
      id: "12",
      state: "Rajasthan",
      offices: [{ id: "1", url: img38, title: "Ajmer Nagar Nigam" }],
    },
    {
      id: "13",
      state: "Tamil Nadu",
      offices: [{ id: "1", url: img39, title: "Greater Chennai Corporation" }],
    },
    {
      id: "14",
      state: "Uttarakhand",
      offices: [{ id: "1", url: img40, title: "Uttarakhand Property Tax" }],
    },
    {
      id: "15",
      state: "Uttar Pradesh",
      offices: [
        { id: "1", url: img41, title: "Agra Nagar Nigam" },
        { id: "2", url: img42, title: "Aligarh Nagar Nigam" },
        { id: "3", url: img43, title: "Lalitpur Nagar Palika Parishad" },
        { id: "4", url: img44, title: "Mathura-Vrindavan Nagar Nigam" },
        { id: "5", url: img45, title: "Meerut Municipal Corporation" },
        { id: "6", url: img46, title: "Palia Kalan Nagar Palika" },
        { id: "7", url: img47, title: "Prayagraj Nagar Nigam" },
        { id: "8", url: img48, title: "Sitapur - Nagar Palika Parishad" },
      ],
    },
    {
      id: "16",
      state: "West Bengal",
      offices: [
        {
          id: "1",
          url: img49,
          title: "Siliguri Jalpaiguri Development Authority",
        },
      ],
    },
  ],
  containtList: [
    {
      id: "1",
      heading: "Use Abaris Payment for Paying Municipal Tax Payments",
      paragraphs: [
        {
          id: "1",
          paragraph:
            "Municipal tax payments are taxes that you pay to the government or local municipal corporation against your property. These taxes are applicable on all types of properties including residential houses, office space, land, etc.",
        },
        {
          id: "2",
          paragraph:
            "Gone are the days when you had to go to the municipal office and wait for long hours in the queue in order to pay your taxes. Now you can do it anytime, anywhere with just a few clicks right on the Abaris Payment app. It is secure and instant. Not just that, you also have the option to choose from various modes of payment such as Debit cards, Credit Cards, UPI, Abaris Payment wallet, and more.",
        },
      ],
    },
    {
      id: "2",
      heading: "Why Use Abaris Payment For Municipal Tax Payments?",
      paragraphs: [
        {
          topPara:
            "Using Abaris Payment to pay your municipal tax payments is a great option because:",
          data: [
            {
              id: "1",
              strong: "Convenience",
              paragraph:
                "You can pay your municipal tax at your convenience, anytime, anywhere. With Abaris Payment you no longer have to wait in the ques or carry your documents to pay your taxes. Your consumer details will be saved automatically after your first payment, so you don’t need to remember them again. These details get saved in your Abaris Payment account and can be automatically retrieved the next time you wish to make a payment.",
            },
            {
              id: "2",
              strong: "Offers",
              paragraph:
                "On the Abaris Payment app, you will be able to pick up some amazing deals and offers when you pay your municipal tax online. Apart from that you also don’t get charged any additional fee for your municipal tax payments.",
            },
            {
              id: "3",
              strong: "Security",
              paragraph:
                "At Abaris Payment, your financial details are safe and encrypted. So, you do not have to worry about data security. Every time you want to make any transactions, an authentication step will be included making it extremely safe to make your municipal house tax online payment with Abaris Payment.",
            },
          ],
        },
      ],
    },
    {
      id: "3",
      heading: "How To Make Property Tax Payment Online With Abaris Payment?",
      paragraphs: [
        {
          topPara:
            "You can make Municipal Tax Payment Online With Abaris Payment by following these simple steps:",
          lists: [
            { id: "1", list: "Navigate to “Municipal Tax”" },
            { id: "2", list: "Select your municipal corporation" },
            {
              id: "3",
              list: "Enter all relevant information that is asked for",
            },
            { id: "4", list: "Click “Next" },
            { id: "5", list: "Verify your payable amount" },
            { id: "6", list: "Select your mode of payment" },
            { id: "7", list: "That is it! Your payment is now completed" },
          ],
        },
      ],
    },
    {
      id: "4",
      heading: "Frequently Asked Questions On Property Tax Payment Online",
      paragraphs: [
        {
          id: "1",
          heading:
            "How Much Is The Penalty For Paying Municipal Tax Payment After The Due Date?",
          paragraph:
            "Municipal tax payments are taxes that you pay to the government or local municipal corporation against your property. These taxes are applicable on all types of properties including residential houses, office space, land, etc.",
        },
        {
          id: "2",
          heading:
            "What Type Of Properties Are Exempted From The Property Tax?",
          paragraph:
            "Municipal tax payments are taxes that you pay to the government or local municipal corporation against your property. These taxes are applicable on all types of properties including residential houses, office space, land, etc.",
        },
      ],
    },
  ],
};
