import BroadBandApp from "../../components/broadBand/BroadBand";

function BroadBand() {
  return (
    <>
      <BroadBandApp />
    </>
  );
}
export default BroadBand;
