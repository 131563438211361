import icon1 from "./../../assets/img/ACT000000NAT01.webp";
import icon2 from "./../../assets/img/ANIN00000NATG6.webp";
import icon3 from "./../../assets/img/ACTI00000NATAQ.webp";
import icon4 from "./../../assets/img/AIRD00000NAT1E.webp";
import icon5 from "./../../assets/img/RURA00000NAT9E.webp";
import icon6 from "./../../assets/img/AIRN00000MUMMZ.webp";
import icon7 from "./../../assets/img/ATPOST000NAT02.webp";
import icon8 from "./../../assets/img/ALLI00000NATHB.webp";
import icon9 from "./../../assets/img/APPL00000NATKI.webp";
import icon10 from "./../../assets/img/ASIA00000NAT6O.webp";
import icon11 from "./../../assets/img/BFIB00000RAJ81.webp";
import icon12 from "./../../assets/img/BFIB00000RAJ81.webp";
import icon13 from "./../../assets/img/BFIB00000RAJ81.webp";
import icon14 from "./../../assets/img/COMWBB000NAT01.webp";
import icon15 from "./../../assets/img/CONBB0000PUN01.webp";
import icon16 from "./../../assets/img/CORR00000JLD6Z.webp";
import icon17 from "./../../assets/img/DVOIS0000NAT01.webp";
import icon18 from "./../../assets/img/DENB00000NATIO.webp";
import icon19 from "./../../assets/img/DVRB00000NATT5.webp";
import icon20 from "./../../assets/img/DWAN00000NAT5R.webp";
import icon21 from "./../../assets/img/DEEN00000NATO6.webp";
import icon22 from "./../../assets/img/DIGI00000KNRTT.webp";
import icon23 from "./../../assets/img/DIGI00000KNRTT.webp";
import icon24 from "./../../assets/img/EXCE00000NATDP.webp";
import icon25 from "./../../assets/img/FICU00000NATO6.webp";
import icon26 from "./../../assets/img/FLAS00000NATVZ.webp";
import icon27 from "./../../assets/img/FUSNBB000NAT01.webp";
import icon28 from "./../../assets/img/GBPS00000NATQZ.webp";
import icon29 from "./../../assets/img/GBPS00000NATQZ.webp";
import icon30 from "./../../assets/img/GTPL00000NATTA.webp";
import icon31 from "./../../assets/img/GIGA00000NATKY.webp";
import icon32 from "./../../assets/img/HATHWAY00NAT01.webp";
import icon33 from "./../../assets/img/HIRE00000NATQ9.webp";
import icon34 from "./../../assets/img/DVOIS0000NAT02.webp";
import icon35 from "./../../assets/img/INFI00000GOASC.webp";
import icon36 from "./../../assets/img/INST00000CHHZV.webp";
import icon37 from "./../../assets/img/INST00000BIHKL.webp";
// import icon38 from "./../../assets/img/INST00000BIHKL.webp";
import icon39 from "./../../assets/img/KERA00000NATIA.webp";
import icon40 from "./../../assets/img/KING00000THI5U.webp";
import icon41 from "./../../assets/img/BLUE00000NATFV.webp";
import icon42 from "./../../assets/img/LINK00000ALLAB.webp";
import icon43 from "./../../assets/img/LOTU00000KRN1H.webp";
import icon44 from "./../../assets/img/MICR00000NATJR.webp";
import icon45 from "./../../assets/img/MNET00000ASM5W.webp";
import icon46 from "./../../assets/img/NSBN00000NATHC.webp";
import icon47 from "./../../assets/img/NETP00000PUNS8.webp";
import icon48 from "./../../assets/img/NEXTRA000NAT01.webp";
import icon49 from "./../../assets/img/ONEB00000NATBJ.webp";
import icon50 from "./../../assets/img/PINK00000NATFG.webp";
import icon51 from "./../../assets/img/PION00000NAT6G.webp";
import icon52 from "./../../assets/img/QUES00000NATUE.webp";
import icon53 from "./../../assets/img/REIS00000NATD0.webp";
import icon54 from "./../../assets/img/SCCN00000NATKW.webp";
import icon55 from "./../../assets/img/SHIN00000NATSJ.webp";
import icon56 from "./../../assets/img/SKYL00000NATJB.webp";
// import icon57 from "./../../assets/img/.webp";
// import icon58 from "./../../assets/img/.webp";
import icon59 from "./../../assets/img/SPENET000NAT01.webp";
import icon60 from "./../../assets/img/SPID00000JPRHH.webp";
// import icon61 from "./../../assets/img/.webp";
import icon62 from "./../../assets/img/SUPE00000THA67.webp";
import icon63 from "./../../assets/img/SWIF00000NATVE.webp";
import icon64 from "./../../assets/img/TICF00000CHE9K.webp";
// import icon65 from "./../../assets/img/TICF00000CHE9K.webp";
import icon66 from "./../../assets/img/TSKG00000NATHU.webp";
import icon67 from "./../../assets/img/TTN000000NAT01.webp";
import icon68 from "./../../assets/img/TIKO00000NAT01.webp";
import icon69 from "./../../assets/img/TIMB00000NATRQ.webp";
import icon70 from "./../../assets/img/081400000UCNBBOB.webp";
import icon71 from "./../../assets/img/UDUP00000NAT55.webp";
// import icon72 from "./../../assets/img/UDUP00000NAT55.webp";
import icon73 from "./../../assets/img/VFIB00000NATJJ.webp";
// import icon74 from "./../../assets/img/VFIB00000NATJJ.webp";
import icon75 from "./../../assets/img/WEEB00000NATVG.webp";
import icon76 from "./../../assets/img/WISH00000NAT7U.webp";
import icon77 from "./../../assets/img/SPEE00000NATEI.webp";
import icon78 from "./../../assets/img/BALA00000NATU8.webp";
import icon79 from "./../../assets/img/MSBR00000WBLBS.webp";
import icon80 from "./../../assets/img/FABN00000NAT4X.webp";
import icon81 from "./../../assets/img/EWAY00000NATV8.webp";
// import icon82 from "./../../assets/img/.webp";
import icon83 from "./../../assets/img/MANA00000SIKR9.webp";
import icon84 from "./../../assets/img/SSIN00000NATOJ.webp";
import icon85 from "./../../assets/img/UFIB00000NAT10.webp";
import icon86 from "./../../assets/img/INET00000TELT3.webp";
import icon87 from "./../../assets/img/MEGH00000WBL82.webp";
import icon88 from "./../../assets/img/PEER00000PUNE8.webp";
import icon89 from "./../../assets/img/TATA00000NATYO.webp";
import icon90 from "./../../assets/img/ANIN00000NATG6.webp";
import icon91 from "./../../assets/img/COMP00000MAHO8.webp";
import icon92 from "./../../assets/img/DIGI00000FAR40.webp";
import icon93 from "./../../assets/img/GULB00000GULIC.webp";
import icon94 from "./../../assets/img/VILC00000HYD2G.webp";

const broadBandMockData = [
  { id: "1", title: "ACT Fibernet", icon: icon1 },
  { id: "2", title: "ANI Network Pvt Ltd", icon: icon2 },
  { id: "3", title: "Activline Telecom Private Limited", icon: icon3 },
  { id: "4", title: "Air Connect", icon: icon4 },
  { id: "5", title: "AirJaldi - Rural Broadband ", icon: icon5 },
  { id: "6", title: "Airnet Networks", icon: icon6 },
  { id: "7", title: "Airtel Broadband", icon: icon7 },
  { id: "8", title: " Alliance Broadband Services Pvt. Ltd.", icon: icon8 },
  { id: "9", title: "Apple Broadband Services Pvt. Ltd.", icon: icon9 },
  { id: "10", title: "Asianet Broadband", icon: icon10 },
  { id: "11", title: "B Fibernet", icon: icon11 },
  { id: "12", title: "Cherrinet", icon: icon12 },
  {
    id: "13",
    title: "Cloudsky Superfast Broadband & Services Pvt Ltd",
    icon: icon13,
  },
  { id: "14", title: "Comway Broadband", icon: icon14 },
  { id: "15", title: "Connect Broadband", icon: icon15 },
  { id: "16", title: "Correl Internet", icon: icon16 },
  { id: "17", title: "D VoiS Communications Private Ltd", icon: icon17 },
  { id: "18", title: "DEN Broadband", icon: icon18 },
  { id: "19", title: "DVR Broadband Services", icon: icon19 },
  { id: "20", title: "DWAN Supports Private Ltd", icon: icon20 },
  { id: "21", title: "Deenet Services Private Limite", icon: icon21 },
  { id: "22", title: "Digiway Net Pvt. Ltd.", icon: icon22 },
  { id: "23", title: "Ethernet Broadband", icon: icon23 },
  { id: "24", title: "Excell Broadband", icon: icon24 },
  { id: "25", title: "Ficus Telecom Pvt Ltd", icon: icon25 },
  { id: "26", title: "Flash Fibernet", icon: icon26 },
  { id: "27", title: "Fusionnet Web Services Private Limited", icon: icon27 },
  { id: "28", title: "GBPS Networks Pvt Ltd", icon: icon28 },
  { id: "29", title: "GNET", icon: icon29 },
  { id: "30", title: "GTPL KCBPL Broadband Pvt Ltd", icon: icon30 },
  { id: "31", title: "Gigatel Networks", icon: icon31 },
  { id: "32", title: "Hathway Broadband", icon: icon32 },
  { id: "33", title: "Hi Reach Broadband", icon: icon33 },
  { id: "34", title: "ION", icon: icon34 },
  { id: "35", title: "Infinet", icon: icon35 },
  { id: "36", title: "Instalinks", icon: icon36 },
  { id: "37", title: "Instanet Broadband", icon: icon37 },
  { id: "38", title: "Jiffy Cable And Datacom Private Limited" },
  { id: "39", title: " Kerala Vision Broadband Pvt Ltd", icon: icon39 },
  { id: "40", title: "Kings Broadban", icon: icon40 },
  { id: "41", title: "Limras Eronet", icon: icon41 },
  { id: "42", title: "Linkio Fibernet Pvt. Ltd.", icon: icon42 },
  { id: "43", title: "Lotus Broadband.", icon: icon43 },
  { id: "44", title: "Microscan Infocommtech Pvt. Ltd.", icon: icon44 },
  { id: "45", title: "Mnet Broadband.", icon: icon45 },
  { id: "46", title: " NS Broadband.", icon: icon46 },
  { id: "47", title: " Netplus Broadband.", icon: icon47 },
  { id: "48", title: " Nextra Broadband.", icon: icon48 },
  { id: "49", title: "One OTT Intertenment Limited.", icon: icon49 },
  { id: "50", title: "Pink Broadband.", icon: icon50 },
  { id: "51", title: "Pioneer Elabs Limited.", icon: icon51 },
  { id: "52", title: "Quest Consultancy Pvt Ltd.", icon: icon52 },
  { id: "53", title: "Reisnet Broadband.", icon: icon53 },
  { id: "54", title: "SCC Network Pvt Ltd.", icon: icon54 },
  { id: "55", title: "Shine Communications Pvt Ltd.", icon: icon55 },
  { id: "56", title: "Skylink Fibernet Private Limited.", icon: icon56 },
  { id: "57", title: "Skylink Fibernet Private Limited." },
  { id: "58", title: "Skylink Fibernet Private Limited." },
  { id: "59", title: "Spectra.", icon: icon59 },
  { id: "60", title: "Spiderlink Networks Pvt Ltd.", icon: icon60 },
  { id: "61", title: "Spidernet Broadband." },
  { id: "62", title: "Super Sonic Broadband Private Limited.", icon: icon62 },
  { id: "63", title: "Swifttele Enterprises Private Limited.", icon: icon63 },
  { id: "64", title: "TIC Fiber Pvt.Ltd.", icon: icon64 },
  { id: "65", title: "TJ Broadband Network Pvt Ltd" },
  { id: "66", title: "TSK Giga Fibber", icon: icon66 },
  { id: "67", title: "TTN BroadBand", icon: icon67 },
  { id: "68", title: "Tikona Infinet Pvt Ltd", icon: icon68 },
  { id: "69", title: "Timbl Broadband", icon: icon69 },
  { id: "70", title: "UCN Broadband", icon: icon70 },
  { id: "71", title: "Udupi Fastnet Pvt Ltd", icon: icon71 },
  { id: "72", title: " VDV Digital" },
  { id: "73", title: " Vfibernet Broadband", icon: icon73 },
  { id: "74", title: " Way2Net" },
  { id: "75", title: " Weebo Networks Pvt Ltd", icon: icon75 },
  { id: "76", title: " Wish Net Pvt Ltd", icon: icon76 },
  { id: "77", title: " Speednet Unique Network ", icon: icon77 },
  { id: "78", title: " Balaji Broadband ", icon: icon78 },
  { id: "79", title: " MS Broadband ", icon: icon79 },
  { id: "80", title: " FABNET ", icon: icon80 },
  { id: "81", title: " Eway FiberNet", icon: icon81 },
  { id: "82", title: " DHL Fibernet" },
  { id: "83", title: "Manas Broadband", icon: icon83 },
  { id: "84", title: "SS Internet", icon: icon84 },
  { id: "85", title: "Ufibernet", icon: icon85 },
  { id: "86", title: "I Net Broadband", icon: icon86 },
  { id: "87", title: "Meghlink", icon: icon87 },
  { id: "88", title: "PeerCast", icon: icon88 },
  { id: "89", title: "Tata Play Fiber", icon: icon89 },
  { id: "90", title: "ANI Broadband", icon: icon90 },
  { id: "91", title: "Compliance Broadband (CBPL)", icon: icon91 },
  { id: "92", title: "Digital World", icon: icon92 },
  { id: "93", title: "Gulbarga Mega Speed", icon: icon93 },
  { id: "94", title: "VILCOM", icon: icon94 },
];
export default broadBandMockData;
