import TaxPaymentForm from "../../components/taxPayment/Tax-Payment-Form"


function TaxPayment() {
    return (
        <div>
            <TaxPaymentForm/>
        </div>
    )
}
export default TaxPayment