import React, { useState } from 'react';
import '../../../assets/css/style.css';
import '../../../assets/css/responsive.css';
import ownerInfo from '../../../assets/img/owner-info.svg';
import trendingIcon from '../../../assets/img/trending-icon.png';

import { rentCreditData } from '../../../pages/rentCreditPage/mockData';

function RentCreditForm() {
    const [formVal, setFormVal] = useState(rentCreditData);
    const changeHandler = (e) => {
        const val = e.target.value;
        const inputName = e.target.name;
        const updatedForm = { ...formVal }
        updatedForm[inputName] = val;
        setFormVal(updatedForm)
    }
    const submitForm = (e) => {
        e.preventDefault();
        console.log(formVal)
    }
    return (
        <>
            <div className="main-content desktop-view-pending">
                
                <div className="progress-bar-content">
                    <div className="progress-step-details">Step 1 / 2</div>
                    <div className="progress-bar">
                        <div className="progress-bar-line" style={{ background: "rgb(252, 121, 68)", width: "50%" }}></div>
                    </div>
                </div>
                <div className="heading-section">
                    <img className="heading-image" src={ownerInfo}
                        alt="heading icon" />
                    <div className="heading-content">
                        <p className="heading-text">Recipient's Details</p>
                        <p className="sub-heading-text">Please enter the personal and banking details</p>
                    </div>
                </div>
                <div className="add-landlord-details-section">
                    <div className="rentals-count-section">
                        <img src={trendingIcon} alt="Trending Icon" />
                        <p><span>1,30,827 </span>rent payments done in last 7 days</p>
                    </div>
                    <form className="add-landlord-details-form ">
                        <div className="form-fields">
                            <div
                                className="input__InputContainerWrapper-sc-77palv-0 gxoua input-wrapper landlord-name-container ">
                                <div className="input__InputContainer-sc-77palv-1 kjqNrV input-container">
                                    <p
                                        className="input__InputContainerLabelWithoutAnimation-sc-77palv-3 jNKTSn input-label-without-animation ">
                                        Recipient's Name</p>
                                    <input type="text" name="name"
                                        id="addLandlordName" placeholder="Enter Name"
                                        autoComplete="off" className="input__InputWrapper-sc-77palv-4 gjZEHA" value={formVal.name} onChange={changeHandler}
                                    />
                                </div>
                            </div>
                            <div
                                className="input__InputContainerWrapper-sc-77palv-0 gxoua input-wrapper landlord-mobile-number-container ">
                                <div className="input__InputContainer-sc-77palv-1 kjqNrV input-container">
                                    <p className="input__InputContainerLabelWithoutAnimation-sc-77palv-3 jNKTSn input-label-without-animation ">
                                        Mobile Number (Optional)</p>
                                    <input type="number"
                                        name="mobile" 
                                        id="addLandlordMobileNumber"
                                        placeholder="Enter Mobile Number" 
                                        autoComplete="off"
                                        className="input__InputWrapper-sc-77palv-4 gjZEHA" 
                                        value={formVal.mobile} 
                                        onChange={changeHandler} />
                                </div>
                            </div>
                            <div
                                className="input__InputContainerWrapper-sc-77palv-0 gxoua input-wrapper landlord-account-number-container ">
                                <div className="input__InputContainer-sc-77palv-1 kjqNrV input-container">
                                    <p className="input__InputContainerLabelWithoutAnimation-sc-77palv-3 jNKTSn input-label-without-animation ">Bank Account Number</p>
                                    <input type="number" 
                                        name="account"
                                        id="addLandlordAccountNumber" 
                                        placeholder="Enter Bank Account Number"
                                        autoComplete="off"
                                        className="input__InputWrapper-sc-77palv-4 gjZEHA" 
                                        value={formVal.account}
                                        onChange={changeHandler}
                                         />
                                </div>
                            </div>
                            <div
                                className="input__InputContainerWrapper-sc-77palv-0 gxoua input-wrapper landlord-confirm-account-number-container ">
                                <div className="input__InputContainer-sc-77palv-1 kjqNrV input-container">
                                    <p
                                        className="input__InputContainerLabelWithoutAnimation-sc-77palv-3 jNKTSn input-label-without-animation ">
                                        Confirm Bank Account Number</p>
                                    <input type="number"
                                        name="confirmAccount"
                                        id="addLandlordConfirmAccountNumber"
                                        placeholder="Re-enter Bank Account Number"
                                        autoComplete="off" className="input__InputWrapper-sc-77palv-4 gjZEHA" value={formVal.confirmAccount} 
                                        onChange={changeHandler}
                                    />
                                </div>
                            </div>
                            <div
                                className="input__InputContainerWrapper-sc-77palv-0 gxoua input-wrapper ifsc-code-container ">
                                <div className="input__InputContainer-sc-77palv-1 kjqNrV input-container">
                                    <p
                                        className="input__InputContainerLabelWithoutAnimation-sc-77palv-3 jNKTSn input-label-without-animation ">
                                        IFSC Code</p>
                                    <input type="text" 
                                        name="ifscCode"
                                        id="addLandlordIfscCode" placeholder="Enter IFSC Code"
                                        maxLength="11" autoComplete="off"
                                        className="input__InputWrapper-sc-77palv-4 gjZEHA" 
                                        value={formVal.ifscCode}
                                        onChange={changeHandler} />
                                </div>
                            </div>
                        </div>
                        <div className="form-btns">
                            <button onClick={submitForm}>Next</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default RentCreditForm