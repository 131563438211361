import HomeTab from "../../components/home/HomeTab";

function HomePage() {
    const backgroundImg = "bg-rent.svg"
    return (
        <div className="main-container" style={{ background: `url(${backgroundImg})` }}>
            <div className="main-wrapper">
                <div className="main-navigation">
                    <div className="navigation-list  navigation-primary">
                        <div className="list-track">
                            <div className="primary-list">
                                <ul>
                                    <li className="active-option"><span className="label-text" title="Home">Home</span></li>
                                    <li className=""><span className="label-text" title="Loans">Loans</span></li>
                                    <li className="list-divider"></li>
                                    <li className=""><span className="label-text" title="Offers">Offers</span></li>
                                    <li className=""><span className="label-text" title="Help and Support">Help and
                                            Support</span></li>
                                </ul>
                            </div>
                            <div className="secondary-list">
                                <label className="back-label">
                                    <img src="assets/img/back.png" alt="sidebar back icon"
                                        loading="lazy"/>
                                    back
                                </label>
                                <h6>More Option</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <HomeTab />
                {/* <aside className="main-aside "></aside> */}
            </div>
        </div>
        
    )
}
export default HomePage;