import { Link } from "react-router-dom";
import React from "react";

import { mockData } from "./Mockdata";

import appstore from "../../assets/img/appstore.png";
import googleplay from "../../assets/img/googleplay.png";
import fb from "../../assets/img/fb.png";
import linkedin from "../../assets/img/linkedin.png";
import twitter from "../../assets/img/twitter.png";
import youtube from "../../assets/img/youtube.png";
import instagram from "../../assets/img/instagram.png";
import certified from "../../assets/img/certified.png";
import axislogo from "../../assets/img/axislogo.png";

const Footer = () => {
  return (
    <>
      <footer className="footer-laptop d-none d-lg-block">
        <div className="footer-section">
          <div className="upper-section">
            <div className="footer-content">
              <div className="footer-list-container">
                {mockData.map((listData) => {
                  return (
                    <div className="footer-list" key={listData.id}>
                      <div className="links-list">
                        <h3>{listData.heading}</h3>
                        <ul>
                          {listData.list.map((item,i) => {
                            return (
                              <li key={i}>
                                <Link to={`/${item.url}`} title="About Us">{item.title}</Link>
                              </li>
                            );
                          })}
                        </ul>
                        {listData.list1 ? (
                          <ul>
                            {listData.list1.map((item) => {
                              return (
                                <li key={item.id}>
                                  <Link to={`/${item.url}`} title="About Us">{item.title}</Link>
                                </li>
                              );
                            })}
                          </ul>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="social-links">
                <div className="social-medias-links">
                  <h3>Join Us</h3>
                  <div className="images-list">
                    <img src={fb} alt="fb" title="Facebook" loading="lazy" />
                    <img
                      src={linkedin}
                      alt="linkedin"
                      title="LinkedIn"
                      loading="lazy"
                    />
                    <img
                      src={twitter}
                      alt="twitter"
                      title="Twitter"
                      loading="lazy"
                    />
                    <img
                      src={youtube}
                      alt="youtube"
                      title="Youtube"
                      loading="lazy"
                    />
                    <img
                      src={instagram}
                      alt="instagram"
                      title="Instagram"
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className="app-links">
                  <h3>Download the App</h3>
                  <div className="images-list">
                    <img
                      className="google-play"
                      src={googleplay}
                      alt="google play"
                      title="Google Play Store"
                      loading="lazy"
                    />
                    <img
                      className="app-store"
                      src={appstore}
                      alt="app store"
                      title="App Store"
                      loading="lazy"
                    />
                  </div>
                </div>
                <img
                  className="certified-image"
                  src={certified}
                  alt="product certified"
                  title="Certified"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="lower-section">
            <p className="text-content">
              © Abaris Softech Pvt. Ltd. All Rights Reserved | Abaris Payment
              Balance Account (Prepaid Payment Instrument) issued by{" "}
              <img src={axislogo} alt="axis logo" loading="lazy" />
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
