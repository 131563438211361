import React, { useState } from "react";
import bgrent from "../../assets/img/bgrent.svg";
import { gasPayementData } from "../../pages/gasPayment/mockData";
import gasheader from "../../assets/img/gasheader.svg";
import gas from "../../assets/img/gas.svg";
import searchicon from "../../assets/img/searchicon.png";

const GasPayment = () => {
  const [selectedData, setSelectedData] = useState(gasPayementData);
  const onHandleChange = (e) => {
    if (e.target.value === "") {
      setSelectedData(gasPayementData);
    } else {
      setSelectedData(
        gasPayementData.filter((data) => {
          return data.title
            .toLowerCase()
            .includes(e.target.value.toLowerCase());
        })
      );
    }
  };
  return (
    <div
      className="main-container"
      style={{ backgroundmage: "url(" + bgrent + ")" }}
    >
      <div className="main-wrapper">
        <div className="main-navigation">
          <div className="navigation-list  navigation-primary">
            <div className="list-track">
              <div className="primary-list">
                <ul>
                  <li className="active-option">
                    <span className="label-text" title="Home">
                      Home
                    </span>
                  </li>
                  <li className="">
                    <span className="label-text" title="Loans">
                      Loans
                    </span>
                  </li>
                  <li className="list-divider"></li>
                  <li className="">
                    <span className="label-text" title="Offers">
                      Offers
                    </span>
                  </li>
                  <li className="">
                    <span className="label-text" title="Help and Support">
                      Help and Support
                    </span>
                  </li>
                </ul>
              </div>
              <div className="secondary-list">
                <label className="back-label">
                  <img
                    src="assets/img/back.png"
                    alt="sidebar back icon"
                    loading="lazy"
                  />
                  back
                </label>
                <h6>More Option</h6>
              </div>
            </div>
          </div>
        </div>

        <div className="main-content color-banner w-80">
          <div className="search-layout new-layout">
            <div className="desktop-operators-main-header review-bill-header">
              <div className="desktop-heading-image">
                <img
                  className="heading-image"
                  src={gasheader}
                  title="Gas Bill Payment Done!"
                  alt="Gas Bill Payment Done!"
                />
              </div>
              <div className="desktop-header-text">
                <div className="main-heading">Let's Get Your</div>
                <div className="sub-heading">Gas Bill Payment Done!</div>
              </div>
              <img
                src={gas}
                title="Gas Bill Payment Done!"
                alt="Gas Bill Payment Done!"
              />
            </div>
            <div className="search-layout-section search-input-wrapper  sticky">
              <div className="desktop-search-heading">Search</div>
              <div className="search-layout-field-wrapper ">
                <div className="search-layout-field-icon">
                  <img src={searchicon} alt="search field icon" />
                </div>
                <div className="input__InputContainerWrapper-sc-77palv-0 gxoua input-wrapper search-layout-input-wrapper ">
                  <div className="input__InputContainer-sc-77palv-1 kjqNrV input-container">
                    <input
                      type="text"
                      name="search-field"
                      placeholder="Search"
                      width="100%"
                      autoComplete="off"
                      className="input__InputWrapper-sc-77palv-4 ldAVAW"
                      onChange={(e) => onHandleChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="search-layout-collapsible-section  collapsed"
              style={{ maxHeight: "100%" }}
            >
              <div className="search-layout-section"></div>
            </div>
            <div className="search-layout-section providers-list">
              <div className="section-head">All Providers</div>
              <div className="all-providers-tile">
                {selectedData.map((data) => {
                  return (
                    <div className="operator-tile  flex-center" tabIndex="0">
                      <div className="logo-container">
                        <img
                          src={data.icon}
                          alt="Operator Logo"
                          className="operator-img"
                        />
                      </div>
                      <div className="operator-content">
                        <div className="operator-name">{data.title}</div>
                      </div>
                    </div>
                  );
                })}
                {/* <div className="operator-tile  flex-center" tabIndex="0">
                                        <div className="logo-container"><img src="assets/img/AGL000000MAP01.webp"
                                                alt="Operator Logo" className="operator-img"/></div>
                                        <div className="operator-content">
                                            <div className="operator-name">Aavantika Gas Ltd</div>
                                        </div>
                                    </div>
                                    <div className="operator-tile  flex-center" tabIndex="0">
                                        <div className="logo-container"><img src="assets/img/ADAN00000NAT01.webp"
                                                alt="Operator Logo" className="operator-img"/></div>
                                        <div className="operator-content">
                                            <div className="operator-name">Adani Gas</div>
                                        </div>
                                    </div>
                                    <div className="operator-tile  flex-center" tabIndex="0">
                                        <div className="logo-container"><img src="assets/img/ASSA00000ASMA5.webp"
                                                alt="Operator Logo" className="operator-img"/></div>
                                        <div className="operator-content">
                                            <div className="operator-name">Assam Gas Company Limited</div>
                                        </div>
                                    </div>
                                    <div className="operator-tile  flex-center" tabIndex="0">
                                        <div className="logo-container"><img src="assets/img/BHAG00000NATBJ.webp"
                                                alt="Operator Logo" className="operator-img"/></div>
                                        <div className="operator-content">
                                            <div className="operator-name">Bhagyanagar Gas Limited</div>
                                        </div>
                                    </div>
                                    <div className="operator-tile  flex-center" tabIndex="0">
                                        <div className="logo-container"><img src="assets/img/CUGL00000UTP01.webp"
                                                alt="Operator Logo" className="operator-img"/></div>
                                        <div className="operator-content">
                                            <div className="operator-name">Central U.P. Gas Limited</div>
                                        </div>
                                    </div>
                                    <div className="operator-tile  flex-center" tabIndex="0">
                                        <div className="logo-container"><img src="assets/img/CGSM00000GUJ01.webp"
                                                alt="Operator Logo" className="operator-img"/></div>
                                        <div className="operator-content">
                                            <div className="operator-name">Charotar Gas Sahakari Mandali Ltd</div>
                                        </div>
                                    </div>
                                    <div className="operator-tile  flex-center" tabIndex="0">
                                        <div className="logo-container"><img src="assets/img/GAIL00000IND01.webp"
                                                alt="Operator Logo" className="operator-img"/></div>
                                        <div className="operator-content">
                                            <div className="operator-name">GAIL Gas Limited</div>
                                        </div>
                                    </div>
                                    <div className="operator-tile  flex-center" tabIndex="0">
                                        <div className="logo-container"><img src="assets/img/GAIL00000NATTZ.webp"
                                                alt="Operator Logo" className="operator-img"/></div>
                                        <div className="operator-content">
                                            <div className="operator-name">GAIL India Limited</div>
                                        </div>
                                    </div>
                                    <div className="operator-tile  flex-center" tabIndex="0">
                                        <div className="logo-container"><img src="assets/img/GGL000000UTP01.webp"
                                                alt="Operator Logo" className="operator-img"/></div>
                                        <div className="operator-content">
                                            <div className="operator-name">Green Gas Limited (GGL)</div>
                                        </div>
                                    </div>
                                    <div className="operator-tile  flex-center" tabIndex="0">
                                        <div className="logo-container"><img src="assets/img/GUJGAS000GUJ01.webp"
                                                alt="Operator Logo" className="operator-img"/></div>
                                        <div className="operator-content">
                                            <div className="operator-name">Gujarat Gas Limited</div>
                                        </div>
                                    </div>
                                    <div className="operator-tile  flex-center" tabIndex="0">
                                        <div className="logo-container"><img src="assets/img/GUJA00000GUJYT.webp"
                                                alt="Operator Logo" className="operator-img"/></div>
                                        <div className="operator-content">
                                            <div className="operator-name">Gujarat State Petronet Limited (GSPL)</div>
                                        </div>
                                    </div>
                                    <div className="operator-tile  flex-center" tabIndex="0">
                                        <div className="logo-container"><img src="assets/img/HCG000000HAR02.webp"
                                                alt="Operator Logo" className="operator-img"/></div>
                                        <div className="operator-content">
                                            <div className="operator-name">Haryana City Gas - Kapil Chopra Enterprise</div>
                                        </div>
                                    </div>
                                    <div className="operator-tile  flex-center" tabIndex="0">
                                        <div className="logo-container"><img src="assets/img/IEPL00000GUJ01.webp"
                                                alt="Operator Logo" className="operator-img"/></div>
                                        <div className="operator-content">
                                            <div className="operator-name">IRM Energy Private Limited</div>
                                        </div>
                                    </div>
                                    <div className="operator-tile  flex-center" tabIndex="0">
                                        <div className="logo-container"><img src="assets/img/INDI00000NAT26.webp"
                                                alt="Operator Logo" className="operator-img"/></div>
                                        <div className="operator-content">
                                            <div className="operator-name">Indian Oil Corporation Ltd (IOCL) - Piped Gas
                                            </div>
                                        </div>
                                    </div>
                                    <div className="operator-tile  flex-center" tabIndex="0">
                                        <div className="logo-container"><img src="assets/img/IOAG00000DEL01.webp"
                                                alt="Operator Logo" className="operator-img"/></div>
                                        <div className="operator-content">
                                            <div className="operator-name">Indian Oil-Adani Gas Private Limited</div>
                                        </div>
                                    </div>
                                    <div className="operator-tile  flex-center" tabIndex="0">
                                        <div className="logo-container"><img src="assets/img/INDRAPGASDEL02.webp"
                                                alt="Operator Logo" className="operator-img"/></div>
                                        <div className="operator-content">
                                            <div className="operator-name">Indraprastha Gas (IGL)</div>
                                        </div>
                                    </div>
                                    <div className="operator-tile  flex-center" tabIndex="0">
                                        <div className="logo-container"><img src="assets/img/MAHA00000MUM01.webp"
                                                alt="Operator Logo" className="operator-img"/></div>
                                        <div className="operator-content">
                                            <div className="operator-name">Mahanagar Gas (MGL)</div>
                                        </div>
                                    </div>
                                    <div className="operator-tile  flex-center" tabIndex="0">
                                        <div className="logo-container"><img src="assets/img/MNGL00000MAH02.webp"
                                                alt="Operator Logo" className="operator-img"/></div>
                                        <div className="operator-content">
                                            <div className="operator-name">Maharashtra Natural Gas Limited (MNGL)</div>
                                        </div>
                                    </div>
                                    <div className="operator-tile  flex-center" tabIndex="0">
                                        <div className="logo-container"><img src="assets/img/MEGH00000NATLV.webp"
                                                alt="Operator Logo" className="operator-img"/></div>
                                        <div className="operator-content">
                                            <div className="operator-name">Megha Gas</div>
                                        </div>
                                    </div>
                                    <div className="operator-tile  flex-center" tabIndex="0">
                                        <div className="logo-container"><img src="assets/img/NAVE00000MAPEN.webp"
                                                alt="Operator Logo" className="operator-img"/></div>
                                        <div className="operator-content">
                                            <div className="operator-name">Naveriya Gas Pvt Ltd</div>
                                        </div>
                                    </div>
                                    <div className="operator-tile  flex-center" tabIndex="0">
                                        <div className="logo-container"><img src="assets/img/PURB00000NATIU.webp"
                                                alt="Operator Logo" className="operator-img"/></div>
                                        <div className="operator-content">
                                            <div className="operator-name">Purba Bharati Gas Pvt Ltd (PBGPL)</div>
                                        </div>
                                    </div>
                                    <div className="operator-tile  flex-center" tabIndex="0">
                                        <div className="logo-container"><img src="assets/img/RAJA00000NAT5O.webp"
                                                alt="Operator Logo" className="operator-img"/></div>
                                        <div className="operator-content">
                                            <div className="operator-name">Rajasthan State Gas Limited</div>
                                        </div>
                                    </div>
                                    <div className="operator-tile  flex-center" tabIndex="0">
                                        <div className="logo-container"><img src="assets/img/SGL000000GUJ01.webp"
                                                alt="Operator Logo" className="operator-img"/></div>
                                        <div className="operator-content">
                                            <div className="operator-name">Sabarmati Gas</div>
                                        </div>
                                    </div>
                                    <div className="operator-tile  flex-center" tabIndex="0">
                                        <div className="logo-container"><img src="assets/img/TORR00000AHD0P.webp"
                                                alt="Operator Logo" className="operator-img"/></div>
                                        <div className="operator-content">
                                            <div className="operator-name">Torrent Gas</div>
                                        </div>
                                    </div>
                                    <div className="operator-tile  flex-center" tabIndex="0">
                                        <div className="logo-container"><img src="assets/img/TNGCLOB00TRI01.webp"
                                                alt="Operator Logo" className="operator-img"/></div>
                                        <div className="operator-content">
                                            <div className="operator-name">Tripura Natural Gas</div>
                                        </div>
                                    </div>
                                    <div className="operator-tile  flex-center" tabIndex="0">
                                        <div className="logo-container"><img src="assets/img/UCPGPL000MAH01.webp"
                                                alt="Operator Logo" className="operator-img"/></div>
                                        <div className="operator-content">
                                            <div className="operator-name">Unique Central Piped Gases Pvt Ltd (UCPGPL)</div>
                                        </div>
                                    </div>
                                    <div className="operator-tile  flex-center" tabIndex="0">
                                        <div className="logo-container"><img src="assets/img/VGL000000GUJ01.webp"
                                                alt="Operator Logo" className="operator-img"/></div>
                                        <div className="operator-content">
                                            <div className="operator-name">Vadodara Gas Limited (VGL)</div>
                                        </div>
                                    </div> */}
              </div>
            </div>
          </div>
          <div className="about-section">
            <div className="content-section" style={{ maxHeight: "100%" }}>
              <h1>Online Gas Bill Payment With Abaris Payment</h1>
              <p>
                Gas bill payment online has become an effective and helpful
                method with Abaris Payment.
              </p>
              <p>
                No matter who your gas service provider is, search well in the
                portal and make your online payment. You can simply trust Abaris
                Payment as it’s the most flexible and safe platform for your
                online bill payments.{" "}
              </p>
              <h2>About Gas Bill Payment Online</h2>
              <p>
                With the rapid boost in smartphone usage and one-click payment
                services, gas bill payment using mobile apps has become much
                simpler and easier than before. Gas bill payment online is the
                easiest, fastest, convenient, and highly safe. Gone are the days
                when you had to stand in a long queue to make your gas bill
                payment as Abaris Payment avails the facility to make such
                payments right at the comfort of your home or office. Make your
                online gas or piped gas bill payment in less than a minute with
                high data encryption assurance in Abaris Payment.{" "}
              </p>
              <p>
                Moreover, online gas bill payment saves you time and protects
                you from any late penalty charges. Apart from the better safety
                than paying via mail, you can pay your bills via Abaris Payment
                using any payment method such as debit card, credit card, UPI,
                etc. Moreover, Abaris Payment gives you exciting rewards and
                cashback for every bill payment you make.{" "}
              </p>
              <h2>How To Make Online Gas Bill Payment?</h2>
              <p>
                It’s crucial to pay your gas bill on time as it ensures an
                uninterrupted gas line service to your home. Online gas bill
                payment is the best way to avoid delays and penalty charges. Any
                user can pay their gas bill online with the Abaris Payment
                mobile app or its official website. Abaris Payment ensures
                minimal hassle, effort, and time to complete your gas bill
                payment process. So, check the steps given below to make your
                online gas bill payment with this app:{" "}
              </p>
              <p>
                ● Open Abaris Payment and navigate to the ‘Recharge and Bill
                Payment’ option. Click on it and choose the ‘Piped Gas’ option.{" "}
              </p>
              <p>
                ● Pick your gas operator and enter your customer reference ID.
              </p>
              <p>
                ● The screen will display your bill details, then press
                ‘Continue.’{" "}
              </p>
              <p>
                ● Make your payment via payment methods such as e-wallet, UPI,
                debit/credit card.
              </p>
              <p>● You will get a confirmation receipt after the payment.</p>
              <p>
                ● Your online cylinder booking can also be taken care of using
                Abaris Payment.{" "}
              </p>
              <h2>Benefits of Gas Pay Online</h2>
              <h3>One-Click Payment</h3>
              <p>
                The user has to give minimal effort. They have to simply open
                the app or website and click once to make their gas bill payment
                online. It’s fast, quick, and safe,{" "}
              </p>
              <h3>Pay From Mobile App</h3>
              <p>
                Online gas payment is now easier than before. Simply download
                the Abaris Payment app, do an account setup with no paperwork,
                add your gas service customer ID, pick the payment method, and
                it’s done.{" "}
              </p>
              <h3>Secure Payment</h3>
              <p>
                With rapid digitization, online transactions have stepped into
                the next level with advanced data encryption and high security.
                With Abaris Payment, your data and money both are highly secured
                and safe.{" "}
              </p>
              <h3>Cashback And Offers</h3>
              <p>
                Abaris Payment ensures unlimited offers, rewards and discounts
                on every gas bill payment. You can avail of the offers while
                saving on every payment checkout.
              </p>
              <h2>Frequently Asked Questions on Gas Bill Payment </h2>
              <h3>
                What payment methods can I use to pay gas bills online with
                Abaris Payment?
              </h3>
              <p>
                You can pay gas bills online with Abaris Payment via various
                payment methods such as a debit card, credit card, UPI,
                e-wallet, and more.{" "}
              </p>
              <h3>
                Can I use the Abaris Payment mobile app for gas bill payment?
              </h3>
              <p>
                Yes, you can use the Abaris Payment mobile app available on the
                Play Store. Anyone can download the app and make a payment in
                less than a minute.{" "}
              </p>
              <p>
                <strong>Conclusion</strong>
              </p>
              <p>
                Now, online gas bill payment has become more easy, simple and
                convenient with Abaris Payment. After selecting the gas
                provider, you can take next step for making the payment via
                Abaris Payment. Being trusted by over 27 million users, you can
                too you Abaris Payment and reach next level of digital gas bill
                payment.{" "}
              </p>
              <p></p>
              <p></p>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GasPayment;
