
function SlideItem({slideItem}) {
  
  return (
    <>
      <div className={"investment-slide-item item "+ (slideItem.bgClass) }>
        <div className="upper-section">
          <img src={slideItem.icon}
                className="investment-image" alt="paylater image"/>
          <span className="investment-title">{slideItem.title}</span>
        </div>
        <div className="lower-section">
          <span className="investment-sub-title">{slideItem.sub_title}</span>
          <button className="button__SecondaryButtonWrapper-ah8457-1 jEfuYg" width="100%" type="button">Apply Now</button>
        </div>
      </div>
    </>
  );
}
export default SlideItem;
